import { createContext } from 'react'
import { User } from '../models/api/APILunchPlan'
import { Platform } from '../types/enum/enums'

export const localStorageValues = {
  login: {
    key: 'foodiento',
    value: 'loggedIn'
  },
  microsoftAccessToken: {
    key: 'foodiento-ms-access-token'
  }
}

export interface UserGlobalData extends User {
  userLoggedIn: boolean;
  userInLunchPlan: boolean;
}

export const UserGlobalDataContext = createContext<UserGlobalData>({
  name: '',
  imgUrl: '',
  telephone: '',
  email: '',
  platform: Platform.INVALID,
  userLoggedIn: false,
  userInLunchPlan: false,
})
