/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { nameMapLink } from '../../helpers/RestaurantsHelper'
import {
  useGetRestaurantDetailsFromGoogle,
  //useMockRestaurantDetailsFromGoogle,
  useGetRestaurantsFromGoogle,
  //useMockRestaurantsFromGoogle,
} from '../../hooks/api/useApi'
import * as S from './RestaurantDetail.styles'
import { RestaurantListData } from '../../types/restaurantList.type'
import { GeoPoint } from 'firebase/firestore'
import { RestaurantEmptyState } from '../../types/enum/emptyStates'
import Loading from '../UI/Loading'
import GoBack from '../UI/GoBack'
import { DetailPageColumnPosition, Routes } from '../../types/enum/enums'
import RestaurantPriceItem from './RestaurantPriceItem'
import RestaurantDistanceItem from './RestaurantDistanceItem'
import RestaurantRatingItem from './RestaurantRatingItem'

const RestaurantDetail = () => {
  const itemId = window.location.hash.split('/').pop()
  const lunchPlansRoute = window.location.href.includes('lunchplans')

  const { data: restaurants } = useGetRestaurantsFromGoogle()
  //const { data: restaurants } = useMockRestaurantsFromGoogle()
  const [restaurantInfo, setRestaurantInfo] = useState<RestaurantListData>({
    id: '',
    name: RestaurantEmptyState.NAME,
    distance: 0,
    location: new GeoPoint(0, 0),
    cuisines: [RestaurantEmptyState.CUISINES],
    href: '',
    priceRange: 0,
  })

  useEffect(() => {
    restaurants.forEach((element: RestaurantListData) => {
      if (element.id === itemId) {
        setRestaurantInfo({
          name: element.name,
          distance: element.distance,
          id: element.id,
          location: element.location,
          cuisines: element.cuisines,
          href: element.href,
          priceRange: element.priceRange,
          imgUrl: element.imgUrl
        })
      }
    })
  }, [restaurants, itemId])

  const { data: restaurantDetails } = useGetRestaurantDetailsFromGoogle(restaurantInfo.id)
  //const { data: restaurantDetails } = useMockRestaurantDetailsFromGoogle(restaurantInfo.id)

  const [weblinkExists, setWebLinkExists] = useState(false)
  useEffect(() => {
    setWebLinkExists(restaurantDetails.webUrl !== RestaurantEmptyState.WEBSITE)
  }, [restaurantDetails])

  const [addressExists, setAddressExists] = useState(false)
  useEffect(() => {
    setAddressExists(restaurantDetails.street !== RestaurantEmptyState.ADDRESS)
  }, [restaurantDetails])

  const [telExists, setTelExists] = useState(false)
  useEffect(() => {
    setTelExists(restaurantDetails.tel !== RestaurantEmptyState.PHONENUMBER)
  }, [restaurantDetails])

  const handleWeblink = () => {
    if (weblinkExists) {
      window.open(restaurantDetails.webUrl, '_blank')
    }
  }

  const handleAddressLink = () => {
    if (addressExists) {
      window.open(nameMapLink(restaurantInfo.name), '_blank')
    }
  }

  const handleTelLink = () => {
    if (telExists) {
      window.open(`tel:${restaurantDetails.tel}`, '_self')
    }
  }

  return (
    <div style={{ height: '36rem', overflowY: 'scroll' }}>
      {!restaurantInfo.id ? (
        <Loading />
      ) : (
        <>
          {lunchPlansRoute ? (
            <GoBack route={Routes.LUNCHPLANS} destinationText={''} />
          ) : (
            <GoBack route={Routes.LOCALRESTAURANTS} destinationText={''} />
          )}
          <S.Header>
            <S.RestaurantImage alt='' src={restaurantInfo.imgUrl} />
            <div style={{ width: '-webkit-fill-available' }}>
              <S.HeaderContainer>
                <S.RestaurantName>{restaurantInfo.name}</S.RestaurantName>
              </S.HeaderContainer>
              <S.HeaderLineBreak />
              <S.GraphicInfoRow>
                <S.RatingColumn>
                  <S.GraphicInfoRowHeading align={DetailPageColumnPosition.START} >RATING</S.GraphicInfoRowHeading>
                  <RestaurantRatingItem rating={restaurantDetails.rating}></RestaurantRatingItem>
                </S.RatingColumn>
                <S.PriceColumn>
                  <S.GraphicInfoRowHeading align={DetailPageColumnPosition.CENTER} >PRICE</S.GraphicInfoRowHeading>
                  <div style={{ height: '12px' }}/>
                  <RestaurantPriceItem show={true} range={restaurantInfo.priceRange ?? 0} />
                </S.PriceColumn>
                <S.DistanceColumn>
                  <S.GraphicInfoRowHeading align={DetailPageColumnPosition.END} >DISTANCE</S.GraphicInfoRowHeading>
                  {/* <RestaurantDistanceItem distance={restaurantInfo.distance} /> */}
                </S.DistanceColumn>
              </S.GraphicInfoRow>
            </div>
          </S.Header>
          <S.LineBreak />
          <S.Main>
            <S.RestaurantDetailSubHeader>Adress</S.RestaurantDetailSubHeader>
            <div onClick={handleAddressLink}>
              <S.RestaurantAddress urlExists={addressExists}>
                {restaurantDetails.street} {restaurantDetails.house}
              </S.RestaurantAddress>
              <S.RestaurantAddress urlExists={addressExists}>
                {restaurantDetails.postalCode} {restaurantDetails.city}
              </S.RestaurantAddress>
            </div>
          </S.Main>
          <S.LineBreak />
          <S.Main>
            <div onClick={handleTelLink}>
              <S.RestaurantDetailSubHeader>Phone</S.RestaurantDetailSubHeader>
              <S.RestaurantDetailHeader urlExists={telExists}>{restaurantDetails.tel}</S.RestaurantDetailHeader>
            </div>
          </S.Main>
          <S.LineBreak />
          <S.Main>
            <S.RestaurantDetailSubHeader>Website</S.RestaurantDetailSubHeader>
            <div onClick={handleWeblink}>
              <S.Web urlExists={weblinkExists}>{restaurantDetails.webUrl}</S.Web>
            </div>
          </S.Main>
          <S.LineBreak />
          <S.ButtonContainer>
            <Link style={{ cursor: 'default', pointerEvents: 'none' }} to={`/createlunchplan/${itemId}`}>
              {!lunchPlansRoute && (
                <S.SubmitButton inactive={false}>
                  <button>Choose restaurant</button>
                </S.SubmitButton>
              )}
            </Link>
          </S.ButtonContainer>
        </>
      )}
    </div>
  )
}

export default RestaurantDetail
