import styled from 'styled-components'
import { device } from '../../styles/global'

export const NavMenu = styled.div`
  border-bottom: 1px solid #E5E5E5;
  padding: 0 24px;
  @media ${device.desktop} {
    display: flex;
    justify-content: center;
  }
`

export const NavContainer = styled.div`
  height: 4rem;
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  @media ${device.desktop} {
    width: 1120px;
    padding: 0;
  }
`

export const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
`

export const Logo = styled.img`
  width: 80.64px;
  height: 10.46px;
  @media ${device.desktop} {
    width: 173px;
    height: 22px;
  }
`

export const Avatar = styled.img`
  border-radius: 50%;
  width: 40px;
  height: 40px;
`
