import { GeoPoint } from 'firebase/firestore'
import { geoPointGoogleMaps } from '../helpers/RestaurantsHelper'
import { GoogleMapsApiService } from '../services/Google.API.service'
import { RestaurantEmptyState } from '../types/enum/emptyStates'
import { GeometryFromGoogleApi, RestaurantDetailData, RestaurantListData } from '../types/restaurantList.type'
export class GoogleMapsApiRepository {
  googleMapsApiService: GoogleMapsApiService

  constructor() {
    this.googleMapsApiService = new GoogleMapsApiService()
  }

  filterRestaurants = (): Promise<RestaurantListData[]> => {
    const promise = new Promise<RestaurantListData[]>((resolve, reject) => {
      this.googleMapsApiService.fetchRestaurants().then((apiData: any) => {
        const restaurants: RestaurantListData[] = []
        apiData.forEach((restaurant: {
          place_id: string,
          name: string,
          geometry: GeometryFromGoogleApi,
          distance: number,
          price_level: number,
          imgUrl: string,
        }) => {
          const r: RestaurantListData = {
            id: restaurant.place_id,
            name: restaurant.name ?? '',
            distance: restaurant.distance ?? 0,
            location: geoPointGoogleMaps(restaurant.geometry.location.lat, restaurant.geometry.location.lng) ?? new GeoPoint(0, 0),
            cuisines: ['Cuisines coming soon'],
            href: '',
            priceRange: restaurant.price_level,
            imgUrl: restaurant.imgUrl
          }
          restaurants.push(r)
        })
        resolve(restaurants)
      }).catch((err) => reject(err))
    })
    return promise
  }

  filterRestaurantDetails = (placeId: string): Promise<RestaurantDetailData> => {
    const promise = new Promise<RestaurantDetailData>((resolve, reject) => {
      this.googleMapsApiService.fetchRestaurantDetails(placeId).then((apiData: any) => {
          const details: RestaurantDetailData = {
            webUrl: apiData.website ?? RestaurantEmptyState.WEBSITE,
            tel: apiData.formatted_phone_number ?? RestaurantEmptyState.PHONENUMBER,
            street: apiData.formatted_address ? apiData.formatted_address.split(',')[0] : RestaurantEmptyState.ADDRESS,
            house: '',
            postalCode: apiData.formatted_address ? apiData.formatted_address.split(',')[1].substring(1) : '',
            city: '',
            rating: apiData.rating ? apiData.rating : ''
          }
          resolve(details)
      }).catch(err => reject(err))
    })
    return promise
  }
}
