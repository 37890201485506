import styled, { createGlobalStyle } from 'styled-components'
import { FontWeight } from './variables'

interface ButtonPageActionProps {
  inactive: boolean;
}

export const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');
  
  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Inter', sans-serif;
    color: #212121;
    overflow: hidden;
    }
`

export const ButtonPageAction = styled.div<ButtonPageActionProps>`
  padding: 0 24px;
  & button {
    width: 100%;
    font-size: 14px;
    line-height: 14px;
    font-weight: ${FontWeight.semiBold};
    border-radius: 12px;
    letter-spacing: -0.01em;
    padding: 16px 95px;
    color: #ffffff;
    border: none;
    background: ${(props) => (props.inactive ? '#bfbfbf' : '#212121')};
    pointer-events: ${(props) => (props.inactive ? 'none' : 'auto')};
  }
  & button:hover {
    text-decoration: underline;
  }
`

export const ButtonItemAction = styled.button`
  font-size: 14px;
  font-weight: ${FontWeight.medium};
  line-height: 17px;
  letter-spacing: -0.4px;
  border-radius: 25px;
`

const size = {
  desktopLimit: '480px',
}

export const device = {
  desktop: `(min-width: ${size.desktopLimit})`,
}