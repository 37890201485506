import styled from 'styled-components'
import { FontWeight } from '../../styles/variables'

export const OverLayContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(190, 190, 190, 0.5);
`

export const ModalContainer = styled.div`
  padding: 24px;
  border-radius: 16px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
`

export const ModalTitle = styled.div`
  font-size: 14px;
  font-weight: ${FontWeight.semiBold};
  margin-bottom: 8px;
  text-align: left;
`

export const ModalInfo = styled.div`
  font-size: 14px;
  font-weight: ${FontWeight.regular};
  margin-bottom: 20px;
  text-align: left;
  color: #404040;
`

export const ButtonModalClose = styled.button`
  padding: 16px 18px;
  border: 1px solid #e5e5e5;
  background: none;
  color: #212121;
  border-radius: 12px;
  margin-top: 20px;
  width: 100%;
  font-weight: ${FontWeight.semiBold};
  font-size: 14px;
  cursor: pointer;
`

export const ModalUserInfo = styled.div`
  height: 50px;
  min-width: 294px;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  justify-content: left;
  &:last-of-type {
    border-bottom: none;
  }
`

export const ModalAvatarImg = styled.img`
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 1rem;
`

export const ModalParticipantName = styled.div`
  font-size: 14px;
  margin-top: 15px;
  margin-bottom: 10px;
`