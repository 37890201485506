import styled from 'styled-components'
import { DetailPageColumnPosition } from '../../types/enum/enums'

interface PriceContainerProps {
  align: DetailPageColumnPosition,
  show: boolean
}

export const PriceContainer = styled.div<PriceContainerProps>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.align};
  margin-bottom: 8px;
  font-size: 15px;
  display: ${props => props.show ? 'block' : 'none'};
`

export const BlackDollars = styled.div`
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 2px;
  margin: 0 1px;
`
