import { LunchPlanData } from '../../types/lunchPlanApi.type'
import { UILunchPlanType } from '../../helpers/LunchPlanTypeHelper'
import { useContext } from 'react'
import { UserGlobalDataContext } from '../../context/User.context'
import {
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  deleteDoc,
} from 'firebase/firestore'
import firebase from '../../firebase'
import * as S from './LunchPlan.styles'
import { firestoreCollection, LunchPlanType, Platform } from '../../types/enum/enums'
import useModal from '../../hooks/useModal'
import * as s from '../Modal/Modal.styles'
import Modal from '../Modal/Modal'
import { UserEmptyState } from '../../types/enum/emptyStates'
import { MS_IMG_LINK_PREFIX, MS_IMG_LINK_SUFFIX } from '../../types/constant/copy/WebLinks'

export const setUIType = (type: number) => {
  return UILunchPlanType(type)
}

const LunchPlan = (props: LunchPlanData) => {
  const userGlobalData = useContext(UserGlobalDataContext)

  const { modalOpen, toggle } = useModal()

  const imgUrl = MS_IMG_LINK_PREFIX + userGlobalData.email + MS_IMG_LINK_SUFFIX

  const joinUser = async () => {
    const joinRef = doc(firebase, firestoreCollection, props.docId)
    await updateDoc(joinRef, {
      participants: arrayUnion({
        name: userGlobalData.name,
        imgUrl: userGlobalData.imgUrl === UserEmptyState.AVATAR ? imgUrl : userGlobalData.imgUrl,
        telephone: userGlobalData.telephone,
        email: userGlobalData.email,
        platform: Platform.WEB
      }),
    })
    scroll(0, 0)
  }

  const leaveGroup = async () => {
    const removeRef = doc(firebase, firestoreCollection, props.docId)
    if (props.participantImgUrls.length > 1) {
      await updateDoc(removeRef, {
        participants: arrayRemove({
          name: userGlobalData.name,
          imgUrl: userGlobalData.imgUrl === UserEmptyState.AVATAR ? imgUrl : userGlobalData.imgUrl,
          telephone: userGlobalData.telephone,
          email: userGlobalData.email,
          platform: Platform.WEB
        }),
      })
      await updateDoc(removeRef, {
        participants: arrayRemove({
          name: userGlobalData.name,
          imgUrl: userGlobalData.imgUrl === UserEmptyState.AVATAR ? imgUrl : userGlobalData.imgUrl,
          telephone: userGlobalData.telephone,
          email: userGlobalData.email,
          platform: Platform.IOS
        }),
      })
    } else {
      await deleteDoc(removeRef)
    }
  }

  const cardImage =
    props.type === LunchPlanType.DINE_IN
      ? 'img/dinein.png'
      : 'img/takeaway.png'

  const numberOfParticipants = () => {
    const number = props.participantsArr.length
    const grama = number === 1 ? 'person has' : 'people have'
    return number + ' ' + grama + ' ' + 'joined'
  }

  return (
    <S.Main mainVisible={props.filteredLunchPlans.includes(props.type)}>
      {modalOpen && (
        <Modal
          modalOpen={modalOpen}
          title={props.destination}
          info={`${setUIType(props.type)} Group is leaving at ${props.leavingAt
            }`}
          content={props.participantsArr.map((participant, i) => (
            <s.ModalUserInfo key={i}>
              <s.ModalAvatarImg alt='' src={participant.imgUrl} />
              <s.ModalParticipantName>
                {participant.name}
              </s.ModalParticipantName>
            </s.ModalUserInfo>
          ))}
          handleModalClose={toggle}
        />
      )}
      <S.LunchPlanCTA>
        <S.LunchPlanInfo>
          <S.LunchPlanDescription>
            <S.LunchPlanCardImg src={cardImage} />
            <S.LeavingTime>
              {setUIType(props.type)} Group is Leaving at {props.leavingAt}
            </S.LeavingTime>
          </S.LunchPlanDescription>
          <S.ImgContainer
            onClick={toggle}
          >
            <S.ParticipantIcon src='img/participant-icon.png' />
            <S.ParticipantCount>{numberOfParticipants()}</S.ParticipantCount>
          </S.ImgContainer>
        </S.LunchPlanInfo>
        <S.CTAButtons userInPlan={props.stylePlanNotJoinable} planExpired={props.stylePlanOutdated}>
          <S.JoinButton onClick={joinUser} planExpired={props.stylePlanOutdated}>
            <div>Join</div>
          </S.JoinButton>
          <S.JoinTooltip>Looks like this lunch has finished!</S.JoinTooltip>
        </S.CTAButtons>
        <S.UnjoinBotton userInPlan={props.userInCurrentLunchPlan}>
          <S.JoinButton onClick={leaveGroup}>
            <div>Leave</div>
          </S.JoinButton>
        </S.UnjoinBotton>
      </S.LunchPlanCTA>
    </S.Main>
  )
}

export default LunchPlan
