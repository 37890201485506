import styled from 'styled-components'
import { device } from '../../styles/global'
import { FontWeight } from '../../styles/variables'

export const RestaurantInfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`

export const ItemsLeft = styled.div`
  display: flex;
`
export const RestaurantName = styled.div`
  font-size: 14px;
  font-weight: ${FontWeight.semiBold};
  letter-spacing: -0.02em;
  line-height: 17px;
  margin: 0 0 8px;
  color: #212121;
  @media ${device.desktop} {
    font-size: 18px;
    line-height: 32px;
    font-weight: ${FontWeight.medium};
  }
`

export const RestaurantDistance = styled.div`
  font-size: 14px;
  font-weight: ${FontWeight.regular};
  line-height: 18px;
  color: #6c6c6c;
  @media ${device.desktop} {
    font-size: 16px;
    line-height: 24px;
  }
`

export const ArrowContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Arrow = styled.span`
  font-size: 20px;
  font-weight: ${FontWeight.semiBold};
`