import * as S from './LoadError.styles'

interface LoadErrorProps {
  list: string;
}

const LoadError = ({ list }: LoadErrorProps) => {
  return (
    <S.Main>
      <S.ErrorHeading data-test='error-heading'>Error.</S.ErrorHeading>
      <S.ErrorSubHeading>{list} can not be loaded</S.ErrorSubHeading>
      <S.ErrorStatement data-test='error-statement'>
        Please check your data connection and try again at a later stage.
      </S.ErrorStatement>
      <S.ErrorRetryButton data-test='retry-button' inactive={false} onClick={() => window.location.reload()}>
        <button>Retry</button>
      </S.ErrorRetryButton>
    </S.Main>
  )
}

export default LoadError
