import { LunchPlanType } from '../types/enum/enums'

export const UILunchPlanType = (type: number) => {
  switch (type) {
    case LunchPlanType.DINE_IN:
      return 'Dine-in'
    case LunchPlanType.TAKEAWAY:
    case LunchPlanType.PICK_UP:
      return 'Takeaway'
    default:
      return 'Invalid'
  }
}

export const filterOptions = [
  LunchPlanType.DINE_IN,
  LunchPlanType.PICK_UP,
  LunchPlanType.TAKEAWAY
]
