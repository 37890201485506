import styled from 'styled-components'

interface StarContainerProps {
  fill?: string
}

export const RatingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  height: 40px;
  margin-bottom: 8px;
  font-size: 15px;
`

export const StarContainer = styled.div<StarContainerProps>`
  svg {
    margin-left: 2px;
    path {
      fill: ${(props) => props.fill};
    }
  }
`

export const RatingNumber = styled.p`
  margin: 0 3px 0 0;
`