import {
  Route,
  HashRouter as Router, // TODO: re-introduce BrowserRouter. HashRouter used due to static webpage hosting.
  Switch,
  Redirect,
} from 'react-router-dom'
import Navbar from './components/Navbar/Navbar'
import Home from './components/Home/Home'
import LocalRestaurants from './components/Restaurants/LocalRestaurants'
import RestaurantDetail from './components/Restaurants/RestaurantDetail'
import LunchPlans from './components/LunchPlans/LunchPlans'
import CreateLunchPlan from './components/LunchPlans/CreateLunchPlan'
import { UserGlobalDataContext } from './context/User.context'
import { localStorageValues } from './context/User.context'
import { Routes } from './types/enum/enums'
import { useGetUser } from './hooks/api/useApi'
import UserProfile from './components/UserProfile/UserProfile'
import Footer from './components/Footer/Footer'
import ScrollToTop from './components/UI/ScrollToTop'
import * as S from './components/Footer/Footer.styles'

function App() {
  const { data: userGlobalData } = useGetUser()

  const logVerif = localStorage.getItem(localStorageValues.login.key)

  return (
    <Router>
      <ScrollToTop />
      <S.AboveFooter>
        <UserGlobalDataContext.Provider value={userGlobalData}>
          {logVerif && <Navbar />}
          <Switch>
            <Route exact path={Routes.HOME}>
              {logVerif ? <LunchPlans /> : <Home />}
            </Route>
            <Route exact path={Routes.LOCALRESTAURANTS}>
              {logVerif ? <LocalRestaurants /> : <Redirect to={Routes.HOME} />}
            </Route>
            <Route exact path={Routes.LOCALRESTAURANTDETAIL}>
              {logVerif ? <RestaurantDetail /> : <Redirect to={Routes.HOME} />}
            </Route>
            <Route exact path={Routes.LUNCHPLANSRESTAURANTDETAIL}>
              {logVerif ? <RestaurantDetail /> : <Redirect to={Routes.HOME} />}
            </Route>
            <Route exact path={Routes.LUNCHPLANS}>
              {logVerif ? <LunchPlans /> : <Redirect to={Routes.HOME} />}
            </Route>
            <Route exact path={Routes.CREATELUNCHPLAN}>
              {logVerif ? <CreateLunchPlan /> : <Redirect to={Routes.HOME} />}
            </Route>
            <Route exact path={Routes.USERPROFILE}>
              {logVerif ? <UserProfile /> : <Redirect to={Routes.HOME} />}
            </Route>
          </Switch>
        </UserGlobalDataContext.Provider>
      </S.AboveFooter>
      {logVerif && <Footer />}
    </Router>
  )
}

export default App
