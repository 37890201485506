import { RestaurantListData } from '../../types/restaurantList.type'
import RestaurantInfoSection from './RestaurantInfoSection'
import { useHistory } from 'react-router-dom'
import * as S from './RestaurantListItem.styles'
import { GeoPoint } from 'firebase/firestore'

const RestaurantListItem = (props: RestaurantListData) => {
  const history = useHistory()

  const goToRestaurantDetail = () => {
    history.push(`/localrestaurants/${props.id}`)
  }

  return (
    <>
      <S.RestaurantListItemStyle onClick={goToRestaurantDetail}>
        <RestaurantInfoSection
          id={props.id}
          name={props.name}
          // distance={props.distance}
          location={new GeoPoint(0, 0)}
          cuisines={props.cuisines}
          href={props.href}
          priceRange={props.priceRange}
          show={true}
        />
      </S.RestaurantListItemStyle>
    </>
  )
}

export default RestaurantListItem
