import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import {
  GoogleAuthProvider,
  signInWithPopup,
  getAuth,
  signOut,
  OAuthProvider,
} from 'firebase/auth'
import { localStorageValues } from './context/User.context'
import { loginIsMicrosoft } from './helpers/AuthHelper'
import { microsoftProviderTenant } from './types/enum/enums'
import { LOG_OUT_ALERT } from './types/constant/copy/Alerts'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PID,
  storageBucket: process.env.REACT_APP_SB,
  messagingSenderId: process.env.REACT_APP_SID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MID,
}

const app = initializeApp(firebaseConfig)
const firebase = getFirestore(app)
export default firebase

const provider = loginIsMicrosoft ? new OAuthProvider('microsoft.com') : new GoogleAuthProvider()

provider.setCustomParameters({
  tenant: loginIsMicrosoft ? microsoftProviderTenant : 'common'
})

const auth = getAuth()

export const signIn = () => {
  signInWithPopup(auth, provider)
    .then((result) => {
      const accessToken = OAuthProvider.credentialFromResult(result)?.accessToken
      localStorage.setItem(localStorageValues.microsoftAccessToken.key, accessToken!)
      window.location.reload()
    })
    .catch((error) => {
      alert(error.message)
    })
}

export const logOut = () => {
  signOut(auth)
    .then(() => {
      alert(LOG_OUT_ALERT)
      localStorage.removeItem(localStorageValues.login.key)
      localStorage.removeItem(localStorageValues.microsoftAccessToken.key)
      window.location.reload()
    })
    .catch((error) => {
      alert(error.message)
    })
}
