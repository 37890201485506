export const PickupQuantity: number[] = [0, 1, 2, 3, 4, 5]

const DepartureTimeHours: string[] = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
]

const DepartureTimeMin: string[] = [
  '00',
  '05',
  '10',
  '15',
  '20',
  '25',
  '30',
  '35',
  '40',
  '45',
  '50',
  '55',
]

const currentTime = new Date()

const currentHour = currentTime.getMinutes() >= Number(DepartureTimeMin.slice(-1)[0]) ? currentTime.getHours() + 1 : currentTime.getHours()
export const selectableHours = DepartureTimeHours.splice(currentHour)

export const nextFiveMinInterval = () => {
  const minutes = currentTime.getMinutes()
  const evenFiveMinutes = minutes + 5 - minutes % 5
  return evenFiveMinutes <= 55 ?
    evenFiveMinutes.toString().length === 1 ? '0' + evenFiveMinutes : evenFiveMinutes.toString()
    : '00'
}
export const selectableMinutesFunc = (departureHours: string) => {
  return Number(departureHours) === currentTime.getHours() ?
    DepartureTimeMin.slice(DepartureTimeMin.indexOf(nextFiveMinInterval())) : DepartureTimeMin
}