import styled from 'styled-components'
import { ButtonPageAction, device } from '../../styles/global'
import { FontWeight } from '../../styles/variables'

interface ButtonHeadProps {
  activeButton: boolean;
}

export const Main = styled.div`
  overflow-y: scroll;
  height: 450px;
  @media ${device.desktop} {
    max-width: 1120px;
    width: 100%;
    margin: 0 auto;
    height: 530px;
  }
`

export const Header = styled.div`
  margin-bottom: 16px;
  padding: 24px 24px 0;
  @media ${device.desktop} {
    margin-bottom: 32px;
  }
`

export const DateField = styled.div`
  font-size: 14px;
  line-height: 17px;
  font-weight: ${FontWeight.medium};
  letter-spacing: -0.03em;
  color: #808080;
`

export const TitleFilterContainer = styled.div`
  position: relative;
  @media ${device.desktop} {
    display: flex;
  }
`

export const TextField = styled.div`
  margin-top: 2px;
  font-size: 32px;
  line-height: 45px;
  font-weight: ${FontWeight.medium};
  letter-spacing: -0.02em;
  color: #212121;
`

export const LunchPlanTypeContainer = styled.div`
  display: flex;
  margin: 16px 0 16px 0;
  @media (min-width: 661px) {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 25%);
    height: fit-content;
    margin: unset;
  }
  @media (max-width: 660px) and (min-width: 480px) {
    position: relative;
    transform: translate(24px, -8px);
  }
`

export const LunchPlanTypeButton = styled.button<ButtonHeadProps>`
  border: 0.5px solid #eaeaea;
  border-radius: 25px;
  cursor: pointer;
  background-color: ${(props) => (props.activeButton ? '#eaeaea' : '#ffffff')};
  color: #212121;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: ${FontWeight.regular};
  line-height: 17px;
  margin-right: 8px;
`

export const HeaderFooter = styled.div`
  border-bottom: 0.3px solid #c6c6c8;
  margin-top: 16px;
  @media ${device.desktop} {
    margin-top: 32px;
  }
`

export const SeparationText = styled.div`
  font-size: 22px;
  line-height: 32px;
  font-weight: ${FontWeight.medium};
  letter-spacing: -0.02em;
  margin: 8px 24px 16px;
  @media ${device.desktop} {
    margin: 8px 0 24px;
  }
`
export const EmptyContentContainer = styled.div`
  margin-top: 125px;
`

export const EmptyContentHeader = styled.div`
  text-align: center;
  font-size: 22px;
  line-height: 32px;
  font-weight: ${FontWeight.medium};
  padding: 0 3rem;
  margin: 0 0 24px;
`

export const EmptyContent = styled.div`
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  font-weight: ${FontWeight.regular};
  margin: 0 0 32px;
  padding: 0 2rem;
`

export const SubmitButton = styled(ButtonPageAction)`
  padding: 0 24px;
  text-align: center;
  & button {
    background: none;
    color: ${(props) => (props.inactive ? '#bfbfbf' : '#212121')};
    border: 1px solid #e5e5e5;
  }
  @media ${device.desktop} {
    & button {
      width: fit-content;
      cursor: pointer;
      padding: 16px 100px;
    }
  }
`