import styled from 'styled-components'
import { device } from '../../styles/global'
import { FontWeight } from '../../styles/variables'

export const FooterProps = {
  height: 112,
  marginDesktop: 80,
  marginMobile: 64,
}

const lineHeight = 16

export const Footer = styled.div`
  border-top: 1px solid #e5e5e5;
  padding: calc((${FooterProps.height}px - ${lineHeight}px) / 2) 0;
  font-weight: ${FontWeight.regular};
  font-size: 12px;
  line-height: ${lineHeight}px;
  text-align: center;
`

export const AboveFooter = styled.div`
  min-height: calc(100vh - ${FooterProps.height}px - ${FooterProps.marginMobile}px);
  padding-bottom: ${FooterProps.marginMobile}px;
  @media ${device.desktop} {
    min-height: calc(100vh - ${FooterProps.height}px - ${FooterProps.marginDesktop}px);
    padding-bottom: ${FooterProps.marginDesktop}px;
  }
`