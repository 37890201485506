import * as S from './Loading.styles'
import spinner from '../../assets/spinner.gif'

const Loading = () => {
  return (
    <S.SpinnerContainer>
      <S.Spinner data-test='loading-spinner' src={spinner} alt='spinner' />
    </S.SpinnerContainer>
  )
}

export default Loading
