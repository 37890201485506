import { onChangeSelectElementEvent } from '../types/types'

export const HandleTimeChange = (
  e: onChangeSelectElementEvent,
  setTime: React.Dispatch<
    React.SetStateAction<{
      hours: string;
      minutes: string;
    }>
  >,
  time: { hours: string; minutes: string }
) => {
  const { name, value } = e.target
  setTime({ ...time, [name]: value })
}

export const tempMaintanance = () => {
  alert('This functionality is currently not available due to maintanace, but you are welcome to JOIN any EXISTING Lunch plan. Thanks for your patience')
}
