import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { device } from '../../styles/global'
import { FontWeight } from '../../styles/variables'

export const BackButtonContainer = styled.div`
  border-bottom: 1px solid #e5e5e5;
  padding-left: 24px;
  @media ${device.desktop} {
    display: flex;
    justify-content: center;
  }
`

export const ButtonBack = styled.button`
  padding-left: 24px;
  background-color: transparent;
  border: none;
  outline: none;
  margin-right: 24px;
  @media ${device.desktop} {
    padding-left: 0;
    width: 1120px;
    margin: 0 auto;
    text-align: left;
  }
`
export const HomeLink = styled(Link)`
  text-decoration: none;
  width: fit-content;
`

export const HomeLinkText = styled.div`
  font-weight: ${FontWeight.regular};
  font-size: 14px;
  line-height: 18px;
  color: #212121;
  padding: 16px 0;
  @media ${device.desktop} {
    font-size: 16px;
    line-height: 24px;
    padding: 24px 0;
  }
`
