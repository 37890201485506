import RestaurantListItem from './RestaurantListItem'
import { useEffect, useState } from 'react'
import { RestaurantListData } from '../../types/restaurantList.type'
import { onChangeInputElementEvent } from '../../types/types'
import {
  useGetRestaurantsFromGoogle,
  //useMockRestaurantsFromGoogle
} from '../../hooks/api/useApi'
import LoadError from '../UI/LoadError'
import Loading from '../UI/Loading'
import { Routes } from '../../types/enum/enums'
import * as S from './LocalRestaurants.styles'
import GoBack from '../UI/GoBack'

const LocalRestaurants = () => {
  const { data: restaurants } = useGetRestaurantsFromGoogle()
  //const { data: restaurants } = useMockRestaurantsFromGoogle()

  const [restaurantSearchValue, setRestaurantSearchValue] =
    useState<string>('')
  const [distanceSortResults, setDistanceSortResults] = useState<
    RestaurantListData[]
  >([])

  const [activeButtonValue, setActiveButtonValue] = useState('0-500')

  useEffect(() => {
    setDistanceSortResults(restaurants)
  }, [restaurants])

  const handleRestaurantSearch = (e: onChangeInputElementEvent) => {
    setRestaurantSearchValue(e.target.value)
  }

  const handleResetSearchValue = () => {
    setRestaurantSearchValue('')
    setDistanceSortResults(restaurants)
  }

  const handleClickDistanceSort = (from: number, to: number) => {
    const filteredDistanceValue = restaurants.filter(
      (restaurant: RestaurantListData) =>
        restaurant.distance && restaurant.distance >= from && restaurant.distance <= to
    )
    setDistanceSortResults(filteredDistanceValue)
    setActiveButtonValue(`${from}-${to}`)
  }

  return (
    <>
      <GoBack route={Routes.HOME} destinationText={'to home'} />
      <S.Main>
        <S.Header>Find a restaurant</S.Header>
        {restaurants.length === 0 ? (
          <Loading />
        ) : restaurants.length > 1 ? (
          <>
            <S.RestaurantSearchContainer>
              <S.RestaurantSearchInput
                onChange={handleRestaurantSearch}
                type='text'
                placeholder='Search restaurants'
                value={restaurantSearchValue}
              />
              {restaurantSearchValue && (
                <S.ResetSearchButton onClick={handleResetSearchValue}>
                  ×
                </S.ResetSearchButton>
              )}
            </S.RestaurantSearchContainer>
            <S.RestaurantSearchInputBase />
            <S.RestaurantButtonContainer>
              <S.SortRestaurantButton
                onClick={() => handleClickDistanceSort(0, 500)}
                activeButton={activeButtonValue === '0-500'}
              >
                All
              </S.SortRestaurantButton>
              <S.SortRestaurantButton
                onClick={() => handleClickDistanceSort(0, 50)}
                activeButton={activeButtonValue === '0-50'}
              >
                0-50m
              </S.SortRestaurantButton>
              <S.SortRestaurantButton
                onClick={() => handleClickDistanceSort(50, 150)}
                activeButton={activeButtonValue === '50-150'}
              >
                50m-150m
              </S.SortRestaurantButton>
              <S.SortRestaurantButton
                onClick={() => handleClickDistanceSort(150, 500)}
                activeButton={activeButtonValue === '150-500'}
              >
                150m+
              </S.SortRestaurantButton>
            </S.RestaurantButtonContainer>
            <S.RestaurantList>
              {distanceSortResults
                .filter((restaurant: RestaurantListData) =>
                  restaurant.name
                    .toLowerCase()
                    .includes(restaurantSearchValue.toLowerCase())
                )
                .map((restaurant: RestaurantListData) => (
                  <RestaurantListItem
                    key={restaurant.id}
                    name={restaurant.name}
                    distance={restaurant.distance}
                    id={restaurant.id}
                    location={restaurant.location}
                    cuisines={restaurant.cuisines}
                    href={restaurant.href}
                    priceRange={restaurant.priceRange}
                  />
                ))}
            </S.RestaurantList>
          </>
        ) : (
          <LoadError list={'Restaurants'} />
        )}
      </S.Main>
    </>
  )
}

export default LocalRestaurants
