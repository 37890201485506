export enum RestaurantEmptyState {
  DISTANCE = 'Distance can not be measured',
  ADDRESS = 'Location not available',
  PHONENUMBER = 'Phone number not available',
  WEBSITE = 'Website not available',
  NAME = 'Name not available',
  CUISINES = 'Description not available',
}

export enum UserEmptyState {
  AVATAR = 'img/avatar-empty-state.png',
}
