import { useHistory } from 'react-router-dom'
import * as S from './Navbar.styles'
import { Routes } from '../../types/enum/enums'
import { UserGlobalDataContext } from '../../context/User.context'
import { useContext } from 'react'
import { loginIsMicrosoft } from '../../helpers/AuthHelper'
import { useGetMsUserImg } from '../../hooks/api/useApi'

const Navbar = () => {
  const history = useHistory()
  const userGlobalData = useContext(UserGlobalDataContext)
  const googleImgUrl = userGlobalData.imgUrl

  const goToUserProfile = () => {
    history.push(Routes.USERPROFILE)
  }
  const goHome = () => {
    history.push(Routes.HOME)
  }

  const { data: msImgUrl } = useGetMsUserImg(userGlobalData.email)

  const imgUrl = loginIsMicrosoft ? msImgUrl : googleImgUrl

  return (
    <>
      <S.NavMenu>
        <S.NavContainer>
          <S.ImgContainer>
            <S.Logo alt='foodientoLogo' src='img/logo.png' onClick={goHome} />
          </S.ImgContainer>
          <S.ImgContainer>
            <S.Avatar alt='userAvatar' src={imgUrl} onClick={goToUserProfile} />
          </S.ImgContainer>
        </S.NavContainer>
      </S.NavMenu>
    </>
  )
}

export default Navbar
