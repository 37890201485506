import styled from 'styled-components'
import { device } from '../../styles/global'
import { FontWeight } from '../../styles/variables'

interface MainProps {
  mainVisible: boolean;
}

interface CTAButtonsProps {
  userInPlan?: boolean;
  planExpired?: boolean;
}

const Row = styled.div`
  display: flex;
  align-items: center;
`

export const Main = styled.div <MainProps>`
  padding: 16px 0;
  display: ${(props) => (props.mainVisible ? 'block' : 'none')};
  border-top: 1px solid #e5e5e5;
  @media ${device.desktop} {
    padding: 24px 0;
  }
`

export const LunchPlanCTA = styled(Row)`
  justify-content: space-between;
`

export const LunchPlanInfo = styled.div`
  display: flex;
  flex-direction: column;
  @media ${device.desktop} {
    flex-direction: row;
  }
`

export const LunchPlanDescription = styled(Row)`
  justify-content: flex-start;
`

export const LunchPlanCardImg = styled.img`
  padding: 4px 0;
  margin-right: 16px;
  height: 28px;
  width: 28px;
  @media ${device.desktop} {
    height: 48px;
    width: 48px;
    margin-right: 39px;
  }
`

export const LeavingTime = styled.div`
  color: #404040;
  font-size: 14px;
  font-weight: ${FontWeight.regular};
  line-height: 18px;
  width: 160px;
  @media ${device.desktop} {
    width: 330px;
    font-size: 16px;
    line-height: 24px;
    margin-right: 113px;
  }
`

export const ImgContainer = styled.div`
  width: fit-content;
  border-radius: 20px;
  padding: 4px 0 0;
  display: flex;
  justify-content: space-evenly;
  cursor: pointer;
`

export const JoinButton = styled.button<CTAButtonsProps>`
  padding: 8px 16px;
  border: ${props => props.planExpired ? 'none' : '1px solid #212121'};
  background:${props => props.planExpired ? '#bfbfbf' : '#212121'};
  color: #ffffff;
  border-radius: 25px;
  height: fit-content;
  cursor: 'pointer';
  pointer-events: ${props => props.planExpired ? 'none' : 'default'};
  & div {
    font-size: 14px;
    font-weight: ${FontWeight.regular};
    line-height: 18px;
  }
  &:hover {
    text-decoration: underline;
  }
`

export const JoinTooltip = styled.span`
  position: absolute;
  background-color: #ffffff;
  top: 2.5rem;
  width: max-content;
  visibility: hidden;
  font-size: 12px;
`

export const CTAButtons = styled.div<CTAButtonsProps>`
  display: ${(props) => (props.userInPlan ? 'none' : 'flex')};
  justify-content: flex-end;
  height: 36px;
  position: relative;
  &:hover span {
    @media ${device.desktop} {
    visibility: ${props => props.planExpired ? 'visible' : 'hidden'};
    }
  }
`

export const UnjoinBotton = styled(CTAButtons)`
  display: ${(props) => (props.userInPlan ? 'flex' : 'none')};
`
export const ParticipantIcon = styled.img`
  width: 28px;
  margin: 1rem 1rem 0 0;
  @media ${device.desktop} {
    width: 24px;
    height: 15.83px;
  }
  `

export const ParticipantCount = styled.div`
  align-self: end;
  text-decoration: underline;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.01em;
  font-weight: 600;
  @media ${device.desktop} {
    align-self: center;
  }
`