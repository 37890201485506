import { loginIsMicrosoft } from '../../helpers/AuthHelper'
import * as S from './Footer.styles'

const Footer = () => {
  const thisYear = (new Date()).getFullYear()
  const footerText = loginIsMicrosoft ? `Copyright © Mobiento ${thisYear}. All rights reserved.` : 'STAGING'
  return (
    <S.Footer data-test='footer-text'>{footerText}</S.Footer>
  )
}

export default Footer