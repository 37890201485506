import styled from 'styled-components'
import { ButtonPageAction, device } from '../../styles/global'
import { FontWeight } from '../../styles/variables'

export const Main = styled.div`
  padding: 108px 0 0;
  text-align: center;
  margin: 0 24px;
  @media ${device.desktop} {
    padding: 202px 0 0;
    width: 362px;
  }
`

export const ErrorHeading = styled.div`
  font-weight: ${FontWeight.medium};
  font-size: 22px;
  line-height: 32px;
  letter-spacing: -0.02em;
  @media ${device.desktop} {
    font-size: 32px;
    line-height: 45px;
  }
`

export const ErrorSubHeading = styled.div`
  font-weight: ${FontWeight.regular};
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
`

export const ErrorStatement = styled.div`
  font-weight: ${FontWeight.regular};
  font-size: 14px;
  line-height: 18px;
  padding: 0 24px;
  margin-bottom: 24px;
  @media ${device.desktop} {
    font-size: 16px;
  }
`

export const ErrorRetryButton = styled(ButtonPageAction)`
  padding-top: 10px;
`
