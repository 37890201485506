import { GeoPoint } from 'firebase/firestore'
import { RESTAURANT_SEARCH_PREFIX } from '../types/constant/copy/WebLinks'
import { RestaurantEmptyState } from '../types/enum/emptyStates'
import { TagsFromAPI } from '../types/restaurantList.type'

export const nameMapLink = (restaurantName: string) => {
  const prefix = RESTAURANT_SEARCH_PREFIX
  const name = restaurantName.replace(/\s/g, '%20')
  const suffix = restaurantName.includes(' ')
    ? '%2C%20Stockholm'
    : '%20restaurant%2C%20Stockholm'
  return prefix + name + suffix
}

export const setDistance = (distance: number) => {
  return distance > 0 ? `${distance}m away` : 'distance unknown'
}

export const filterCuisines = (tags: TagsFromAPI[]) => {
  const cuisines: string[] = []
  if (tags) {
    tags.forEach((tag) =>
      cuisines.push(tag.id[0].toUpperCase() + tag.id.slice(1).toLowerCase())
    )
  } else {
    cuisines.push(RestaurantEmptyState.CUISINES)
  }
  return cuisines
}

export const geoPointGoogleMaps = (lat: () => number, lng: () => number) => {
  return new GeoPoint(lat(), lng())
}
