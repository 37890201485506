import { DetailPageColumnPosition } from '../../types/enum/enums'
import * as S from './RestaurantPriceItem.styles'

interface priceRange {
    range: number,
    show: boolean
}

const RestaurantPriceItem = (props: priceRange) => {
    const repeatChar = (char: string, quantity: number) => {
        return char.repeat(quantity)
    }
    return (
        <S.PriceContainer show={props.show} align={DetailPageColumnPosition.START}>
            <S.BlackDollars>{repeatChar('$', props.range)}</S.BlackDollars>
        </S.PriceContainer>
    )
}
export default RestaurantPriceItem