import * as S from './GoBack.styles'

interface GoBackProps {
  route: string;
  destinationText: string;
}

const GoBack = ({ route, destinationText }: GoBackProps) => {
  return (
    <S.BackButtonContainer>
      <S.ButtonBack>
        <S.HomeLink to={route}>
          <S.HomeLinkText>ᐸ Back {destinationText}</S.HomeLinkText>
        </S.HomeLink>
      </S.ButtonBack>
    </S.BackButtonContainer>
  )
}

export default GoBack
