export enum Routes {
  HOME = '/',
  LOCALRESTAURANTS = '/localrestaurants',
  LOCALRESTAURANTDETAIL = '/localrestaurants/:itemId',
  LUNCHPLANS = '/lunchplans',
  LUNCHPLANSRESTAURANTDETAIL = '/lunchplans/:itemId',
  CREATELUNCHPLAN = '/createlunchplan/:itemid',
  USERPROFILE = '/userprofile',
}

export enum LunchPlanType {
  INVALID = -1,
  DINE_IN = 0,
  TAKEAWAY = 1,
  PICK_UP = 2,
  ALL = 3,
}

export const firestoreCollection: string = process.env.REACT_APP_COLLECTION ?? 'lunchplans'
export const microsoftProviderTenant: string = process.env.REACT_APP_MS_PROVIDER_TENANT ?? 'common'

export enum Platform {
  INVALID = -1,
  WEB = 0,
  IOS = 1,
}

export enum DetailPageColumnPosition {
  START = 'start',
  CENTER = 'center',
  END = 'end',
}