import styled from 'styled-components'
import { ButtonPageAction, device } from '../../styles/global'
import { FontWeight } from '../../styles/variables'

export const Main = styled.div`
  margin: 0 1.5rem;
  @media ${device.desktop} {
    padding: 0 24px;
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
  }
`

export const ProfileHeader = styled.div`
  font-weight: ${FontWeight.medium};
  font-size: 32px;
  line-height: 45px;
  letter-spacing: -0.02em;
  margin-bottom: 24px;
  margin-top: 56px;
  @media ${device.desktop} {
    font-size: 42px;
    line-height: 55px;
    margin-bottom: 40px;
  }
`

export const Avatar = styled.img`
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-bottom: 2rem;
  @media ${device.desktop} {
    margin-bottom: 40px;
  }
`

export const BorderTop = styled.div`
  border-bottom: none;
  margin-bottom: 0;
  @media ${device.desktop} {
    border: 1px solid #e5e5e5;
    margin-bottom: 40px;
  }
`

export const UserName = styled.div`
  font-weight: ${FontWeight.medium};
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.02em;
  margin-bottom: 16px;
  @media ${device.desktop} {
    margin-bottom: 32px;
    font-size: 22px;
  }
`

export const ProfileSubHeader = styled.div`
  font-weight: ${FontWeight.regular};
  font-size: 14px;
  line-height: 18px;
  color: #cccccc;
  margin-bottom: 6px;
  @media ${device.desktop} {
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 24px;
  }
`

export const UserEmail = styled(UserName)`
  margin-bottom: 56px;
  @media ${device.desktop} {
    margin-bottom: 32px;
  }
`

export const SignOutButton = styled(ButtonPageAction)`
  padding: 0;
  & button {
    color: #eb5757;
    background: none;
    border: 1px solid #e5e5e5;
    cursor: pointer;
  }
  @media ${device.desktop} {
    width: 362px;
  }
`
