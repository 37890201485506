import * as S from './Modal.styles'

interface ModalProps {
  modalOpen: boolean;
  handleModalClose: () => void;
  title: string;
  info: string;
  content: React.ReactNode;
}

const Modal = ({ handleModalClose, title, info, content }: ModalProps) => {
  return (
    <S.OverLayContainer>
      <S.ModalContainer>
        <S.ModalTitle data-test='modal-restaurant-title'>{title}</S.ModalTitle>
        <S.ModalInfo data-test='modal-lunchplan-info' >{info}</S.ModalInfo>
        {content}
        <S.ButtonModalClose data-test='modal-close-button' onClick={handleModalClose}>Close</S.ButtonModalClose>
      </S.ModalContainer>
    </S.OverLayContainer>
  )
}

export default Modal
