import { HereMapsApiService } from '../services/HereMaps.API.service'
import {
  RestaurantDetailData,
  RestaurantListData,
  TagsFromAPI,
} from '../types/restaurantList.type'
import { filterCuisines } from '../helpers/RestaurantsHelper'
import { RestaurantEmptyState } from '../types/enum/emptyStates'
import { GeoPoint } from 'firebase/firestore'

export class HereMapsApiRepository {
  hereMapsApiService: HereMapsApiService

  constructor() {
    this.hereMapsApiService = new HereMapsApiService()
  }

  getHereMapsData = (): Promise<RestaurantListData[]> => {
    const promise = new Promise<RestaurantListData[]>((resolve, reject) => {
      this.hereMapsApiService
        .getAllRestaurants()
        .then((apiData: any) => {
          const restaurants: RestaurantListData[] = []
          apiData.forEach(
            (data: {
              id: string;
              title: string;
              distance: number;
              imgUrl: string;
              position: GeoPoint;
              tags: TagsFromAPI[];
              href: string;
            }) => {
              const r: RestaurantListData = {
                id: data.id,
                name: data.title ?? '',
                distance: data.distance ?? 0,
                location: data.position ?? new GeoPoint(0, 0),
                cuisines: filterCuisines(data.tags),
                href: data.href ?? '',
              }
              restaurants.push(r)
            }
          )
          resolve(restaurants)
        })
        .catch((err) => reject(err))
    })
    return promise
  }

  getRestDetails = (api: string): Promise<RestaurantDetailData> => {
    const promise = new Promise<RestaurantDetailData>((resolve, reject) => {
      this.hereMapsApiService
        .getRestaurantDetails(api)
        .then((apiData: any) => {
          const details: RestaurantDetailData = {
            webUrl:
              apiData.contacts && apiData.contacts.website
                ? apiData.contacts.website[0].value
                : RestaurantEmptyState.WEBSITE,
            tel:
              apiData.contacts && apiData.contacts.phone
                ? apiData.contacts.phone[0].value
                : RestaurantEmptyState.PHONENUMBER,
            street:
              apiData.location && apiData.location.address
                ? apiData.location.address.street
                : RestaurantEmptyState.ADDRESS,
            house:
              apiData.location && apiData.location.address
                ? apiData.location.address.house
                : '',
            postalCode:
              apiData.location && apiData.location.address
                ? apiData.location.address.postalCode
                : '',
            city:
              apiData.location && apiData.location.address
                ? apiData.location.address.city
                : '',
            rating:
              apiData.rating
                ? apiData.rating
                : '',    
          }
          resolve(details)
        })
        .catch((err) => reject(err))
    })
    return promise
  }
}
