/* eslint-disable @typescript-eslint/no-unused-vars */
import { useHistory } from 'react-router-dom'
import { setDistance } from '../../helpers/RestaurantsHelper'
import { RestaurantListData } from '../../types/restaurantList.type'
import * as S from './RestaurantInfoSection.styles'
import RestaurantPriceItem from './RestaurantPriceItem'

const RestaurantInfoSection = (props: RestaurantListData) => {
  const history = useHistory()

  const goToRestaurantDetail = () => {
    history.push(`/lunchplans/${props.id}`)
  }

  return (
    <S.RestaurantInfoRow onClick={goToRestaurantDetail}>
      <S.ItemsLeft>
        <div>
          <S.RestaurantName>{props.name}</S.RestaurantName>
          <RestaurantPriceItem show={props.show ?? false} range={props.priceRange ?? 0} />
          {/* {props.distance && <S.RestaurantDistance>{setDistance(props.distance)}</S.RestaurantDistance>} */}
        </div>
      </S.ItemsLeft>
      <S.ArrowContainer>
        <S.Arrow>ᐳ</S.Arrow>
      </S.ArrowContainer>
    </S.RestaurantInfoRow>
  )
}

export default RestaurantInfoSection
