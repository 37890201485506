import styled from 'styled-components'
import { ButtonPageAction, device } from '../../styles/global'
import { FontWeight } from '../../styles/variables'

export const HeaderContainer = styled.div`
  display: flex;
  padding: 0 24px 0 2rem;
  @media ${device.desktop} {
    width: 100%;
    padding: 0 0 0 24px;
    border-bottom: 1px solid #E5E5E5;
    }
`

export const HeaderLogoContainer = styled.div`
  display: flex;
  width: 1140px;
  margin: 32px auto;
`

export const HeaderLogo = styled.img`
  width: 80.64px;
  height: 10.46px;
  @media ${device.desktop} {
    width: 173px;
    height: 22px;
  }
`
export const HeaderOne = styled.div`
  padding-left: 2rem;
  margin-bottom: 20px;
  @media ${device.desktop} {
    padding-left: 0;
    margin-bottom: 66px;
  }
`

export const TextSectionP1 = styled.div`
  font-size: 18px;
  font-weight: ${FontWeight.regular};
  letter-spacing: -0.55px;
  line-height: 22px;
  margin-bottom: 4px;
  margin-top: 20px;
  @media ${device.desktop} {
    font-size: 16px;
    text-align: center;
    margin-bottom: 32px;
    margin-top: 160px;
  }
`

export const TextSectionP2 = styled.div`
  font-size: 40px;
  font-weight: ${FontWeight.semiBold};
  letter-spacing: -0.8px;
  line-height: 75px;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 20px;
  width: 12rem;
  @media ${device.desktop} {
    width: 30rem;
    font-size: 42px;
    text-align: center;
    text-underline-offset: 10px;
    margin: 0 auto;
  }
`

export const HeaderTwo = styled.div`
  padding-left: 2rem;
  margin-bottom: 138px;
  margin-right: 80px;
  @media ${device.desktop} {
    padding-left: 0;
    margin-bottom: 46px;
    margin-right: 0;
  }
`

export const TextSectionP3 = styled.div`
  font-size: 16px;
  font-weight: ${FontWeight.regular};
  letter-spacing: 0.25px;
  line-height: 30px;
  width: 236px;
  @media ${device.desktop} {
    width: 30rem;
    margin: 0 auto;
    text-align: center;
    line-height: 24px;
  }
`

export const ButtonContainer = styled.div`
  @media ${device.desktop} {
    display: flex;
    justify-content: center;
  }
`

export const LogInButton = styled(ButtonPageAction)`
  button {
    background: #212121;
    color: #ffffff;
    cursor: pointer;
  }
  & button:active {
    background: #ffffff;
    color: #212121;
  }
  @media ${device.desktop} {
    width: 362px;
  }
`
