import { getAuth } from 'firebase/auth'
import { GeoPoint } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import {
  UserGlobalData,
  localStorageValues,
} from '../../context/User.context'
import { HereMapsApiRepository } from '../../respositories/HereMaps.API.repository'
import { LunchPlanApiRepository } from '../../respositories/LunchPlan.API.repository'
import { LunchPlanOutData } from '../../types/lunchPlanApi.type'
import {
  RestaurantDetailData,
  RestaurantListData,
} from '../../types/restaurantList.type'
import { UserEmptyState } from '../../types/enum/emptyStates'
import { Platform } from '../../types/enum/enums'
import axios from 'axios'
import { MS_IMG_LINK_PREFIX, MS_IMG_LINK_SUFFIX } from '../../types/constant/copy/WebLinks'
import { loginIsMicrosoft } from '../../helpers/AuthHelper'
import { GoogleMapsApiRepository } from '../../respositories/GoogleMaps.API.repository'

const lunchPlanApiRepository: LunchPlanApiRepository =
  new LunchPlanApiRepository()

const hereMapsApiRepository: HereMapsApiRepository =
  new HereMapsApiRepository()

const googleMapsApiRepository: GoogleMapsApiRepository = new GoogleMapsApiRepository()

export const useFetchLunchPlans = () => {
  const [data, setData] = useState<LunchPlanOutData[]>([])
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    lunchPlanApiRepository.getOnSnapshotData((data) => {
      setData(data)
    }, () => setIsError(true))
  }, [])

  return { data, isError }
}

export const useFetchRestaurants = () => {
  const [data, setData] = useState<RestaurantListData[]>([])

  useEffect(() => {
    hereMapsApiRepository
      .getHereMapsData()
      .then((data) => {
        setData(data)
      })
      .catch((err) =>
        err.name === 'Error'
          ? setData([
            {
              id: '',
              name: '',
              distance: 0,
              location: new GeoPoint(0, 0),
              cuisines: [''],
              href: '',
            },
          ])
          : console.log('other error')
      )
  }, [])

  return { data }
}

export const useGetRestaurantsFromGoogle = () => {
  const [data, setData] = useState<RestaurantListData[]>([])
  useEffect(() => {
    googleMapsApiRepository.filterRestaurants().then(data => {
      setData(data)
    })
  }, [])
  return { data }
}

export const useGetRestaurantDetailsFromGoogle = (placeId: string) => {
  const [data, setData] = useState<RestaurantDetailData>({
    webUrl: '',
    tel: '',
    street: '',
    house: '',
    postalCode: '',
    city: '',
    rating: 0
  })
  useEffect(() => {
    googleMapsApiRepository.filterRestaurantDetails(placeId).then(data => {
      setData(data)
    })
  }, [placeId])
  return { data }
}

export const useFetchRestaurantDetails = (api: string) => {
  const [data, setData] = useState<RestaurantDetailData>({
    webUrl: '',
    tel: '',
    street: '',
    house: '',
    postalCode: '',
    city: '',
    rating: 0
  })

  useEffect(() => {
    hereMapsApiRepository
      .getRestDetails(api)
      .then((data) => {
        setData(data)
      })
      .catch((err) =>
        err.name === 'Error'
          ? setData({
            webUrl: '',
            tel: '',
            street: '',
            house: '',
            postalCode: '',
            city: '',
            rating: 0
          })
          : console.log(err)
      )
  }, [api])

  return { data }
}

export const useGetUser = () => {
  const [data, setData] = useState<UserGlobalData>({
    name: '',
    imgUrl: '',
    telephone: '',
    email: '',
    platform: Platform.INVALID,
    userLoggedIn: false,
    userInLunchPlan: false,
  })

  const { data: lunchPlanList } = useFetchLunchPlans()

  const auth = getAuth()

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setData({
          name: user.displayName ?? 'N/A',
          imgUrl: user.photoURL ?? UserEmptyState.AVATAR,
          telephone: user.phoneNumber ?? '',
          email: user.email ?? 'N/A',
          platform: Platform.INVALID,
          userLoggedIn: true,
          userInLunchPlan: lunchPlanList.some((element) =>
            element.lunchPlans.some((plan) =>
              plan.participants.some(
                (participant) => participant.email === user.email
              )
            )
          ),
        })
        localStorage.setItem(localStorageValues.login.key, localStorageValues.login.value)
      } else {
        localStorage.removeItem(localStorageValues.login.key)
      }
    })
  }, [auth, lunchPlanList])

  return { data }
}

export const useGetMsUserImg = (email: string) => {
  const [data, setData] = useState('')

  useEffect(() => {
    if (!loginIsMicrosoft || !email) return

    axios
      .get(MS_IMG_LINK_PREFIX + email + MS_IMG_LINK_SUFFIX,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem(localStorageValues.microsoftAccessToken.key)}`,
          },
          responseType: 'arraybuffer',
        })
      .then(res => {
        const avatar = Buffer.from(res.data, 'binary').toString('base64')
        setData('data:image/jpeg;base64, ' + avatar)
      })
      .catch(err => console.log('error:', err))
  }, [email])

  return { data }
}

//Mock hooks:
export const useMockRestaurantsFromGoogle = () => {
  const [data, setData] = useState<RestaurantListData[]>([])

  useEffect(() => {

    setData([
      {
        id: 'mock1',
        name: 'Mock Restaurant #1',
        distance: 100,
        location: new GeoPoint(0, 0),
        cuisines: ['Mock food 1'],
        href: '',
        priceRange: 1,
        imgUrl: 'https://res.cloudinary.com/tf-lab/image/upload/restaurant/a1eaaed4-6930-4d0a-9155-0681b3aa6df8/057ce285-39e0-4990-a0c4-ca04813071aa.jpg'
      },
      {
        id: 'mock2',
        name: 'Mock Restaurant #2',
        distance: 200,
        location: new GeoPoint(1, 1),
        cuisines: ['Mock food 2'],
        href: '',
        priceRange: 2,
        imgUrl: 'https://d3aux7tjp119y2.cloudfront.net/original_images/Tak2-CMSTemplate_IrMZHla.jpg'
      }
    ])
  }, [])
  return { data }
}

export const useMockRestaurantDetailsFromGoogle = (placeId: string) => {
  const [data, setData] = useState<RestaurantDetailData>({
    webUrl: '',
    tel: '',
    street: '',
    house: '',
    postalCode: '',
    city: '',
    rating: 0,
  })
  const restaurantsMockUrl = 'mockGoogleRestaurants.json'
  useEffect(() => {
    fetch(restaurantsMockUrl)
      .then(res => res.json())
      .then(data => {
        try {
          const restaurant = data.objects.filter((object: any) => object.id === placeId)[0]
          setData({
            webUrl: restaurant.webUrl,
            tel: restaurant.tel,
            street: restaurant.street,
            house: restaurant.house,
            postalCode: restaurant.postalCode,
            city: restaurant.city,
            rating: restaurant.rating
          })
        } catch {
          console.warn('Fetching mock data did not work')
        }
      })
  }, [placeId])
  return { data }
}