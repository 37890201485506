import styled from 'styled-components'
import { ButtonPageAction, device } from '../../styles/global'
import { DROPDOWN_ARROW } from '../../types/constant/copy/WebLinks'
import { FontWeight } from '../../styles/variables'

interface TypeButtonProps {
  activeButton: boolean;
}

export const CreateLunchPlanContainer = styled.div`
  margin: 0 24px;
`

export const Header = styled.div`
  font-size: 22px;
  font-weight: ${FontWeight.medium};
  letter-spacing: -0.02em;
  line-height: 32px;
  margin: 24px 0;
  font-weight: ${FontWeight.medium};
  @media ${device.desktop} {
    margin-top: 80px;
    text-align: center;
  }
`

export const LunchPlanTypeButtonContainer = styled.div`
  display: flex;
  justify-content: left;
  @media ${device.desktop} {
    justify-content: center;
  }
`

export const LunchPlanTypeButton = styled.button<TypeButtonProps>`
  font-size: 16px;
  line-height: 24px;
  background-color: transparent;
  border: none;
  border: 1px solid #e5e5e5;
  border: ${(props) =>
    props.activeButton ? '1px solid #212121' : '1px solid #e5e5e5'};
  font-weight: ${(props) => (props.activeButton ? '600' : '500')};
  width: 134px;
  height: 76px;
  margin-right: 24px;
  border-radius: 10px;
  color: #212121;
  cursor: pointer;
  @media ${device.desktop} {
    font-size: 18px;
    font-weight: ${(props) => (props.activeButton ? '500' : '400')};
    width: 172px;
    height: 98px;
    line-height: 32px;
    letter-spacing: -0.02em;
  }
`

export const LineBreak = styled.div`
  margin-top: 40px;
  border-top: 0.3px solid #c6c6c8;
  @media ${device.desktop} {
    width: 1140px;
    margin: 0 auto;
    margin-top: 40px;
  }
`

export const DepartureTimeHeader = styled(Header)`
  margin-top: 40px;
  @media ${device.desktop} {
    margin-top: 40px;
  }
`

export const DepartureTimeHeading = styled.div`
  margin-bottom: 16px;
  font-size: 16px;
  @media ${device.desktop} {
    text-align: center;
  }
`

export const DepartureTimeSelectContainer = styled.div`
  display: flex;
  @media ${device.desktop} {
    justify-content: center;
  }
`
export const TakeawayDropdownSelect = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-right: 16px;
  font-size: 16px;
  font-weight: ${FontWeight.medium};
  width: 82px;
  height: 38px;
  border-radius: 10px;
  border: 1px solid #212121;
  background: transparent;
  padding-right: 45px;
  direction: rtl;
  outline: none;
  color: #212121;
  cursor: pointer;
  background: url(${DROPDOWN_ARROW}) 80% / 25% no-repeat;
`

export const DuplicateLunchPlanWarning = styled.div`
 margin: 2rem 0 0;
 color: #ff3b30;
 font-weight: ${FontWeight.regular};
 font-size: 14px;
 line-height: 18px;
 @media ${device.desktop} {
   margin: 2rem auto;
   width: 22rem;
   text-align: center;
 }
`

export const ButtonContainer = styled.div`
@media ${device.desktop} {
  display: flex;
  justify-content: center;
  }
`

export const SubmitLunchPlanButton = styled(ButtonPageAction)`
  margin-top: ${(props) => (props.inactive ? '24px' : '53px')};
  button {
    cursor: pointer;
  }
  & button:hover {
    text-decoration: underline;
  }
  & button:active {
    background: #ffffff;
    color: #212121;
  }
  @media ${device.desktop} {
    width: 362px;
    margin-top: ${(props) => (props.inactive ? '0' : '40px')};
  }
`