import { ReactComponent as Star } from '../../assets/rating-star.svg'
import { ReactComponent as StarHalf } from '../../assets/rating-starhalf.svg'
import * as S from './RestaurantRatingItem.styles'

interface rating {
  rating: number;
}

const RestaurantRatingItem = (props: rating) => {
  const { rating } = props
  
  const pickStar = (index: number) => {
    if (rating - index > 0 && rating - index < 1) {
      return (
        <S.StarContainer key={index}>
          <StarHalf />
        </S.StarContainer>
      )
    }
    return (
      <S.StarContainer key={index} fill={rating - index > 1 ? '#212121' : '#BFBFBF'}>
        <Star />
      </S.StarContainer>
    )
  }



  return (
    <S.RatingContainer>
      <S.RatingNumber>{rating}</S.RatingNumber>
      {[...Array(5)].map((_e, i) => {
        return pickStar(i)
      })}
    </S.RatingContainer>
  )
}

export default RestaurantRatingItem