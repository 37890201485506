export const dateSuffix = (date: string) => {
  let suffix = 'th'
  if (date !== '11' && date.slice(-1) === '1') {
    suffix = 'st'
  } else if (date !== '12' && date.slice(-1) === '2') {
    suffix = 'nd'
  } else if (date !== '13' && date.slice(-1) === '3') {
    suffix = 'rd'
  }
  return suffix
}

export const formatLeavingAt = (sec: number) => {
  return new Date(sec * 1000).toLocaleTimeString([], { timeStyle: 'short', hour12: false })
}