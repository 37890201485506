/* eslint-disable @typescript-eslint/no-unused-vars */
import { CoordinatesObj, RestaurantsFromGoogle } from '../types/restaurantList.type'

const mobiento = { lat: 59.3426, lng: 18.0615 }

export class GoogleMapsApiService {
    fetchRestaurants = (): Promise<RestaurantsFromGoogle[]> => {
        const promise = new Promise<RestaurantsFromGoogle[]>((resolve, reject) => {

            const restaurants: RestaurantsFromGoogle[] = []
            let errors: google.maps.places.PlacesServiceStatus[] = []

            const distanceService = new google.maps.DistanceMatrixService()
            const map = new google.maps.Map(
                document.getElementById('map') as HTMLElement,
                {
                    center: mobiento,
                } as google.maps.MapOptions
            )
            
            const service = new google.maps.places.PlacesService(map)

            // const getDistances = async (coordinatesArr: CoordinatesObj[]) => {
            //     let distances: number[] = []
            //     await distanceService.getDistanceMatrix({
            //         origins: [mobiento],
            //         destinations: coordinatesArr,
            //         travelMode: google.maps.TravelMode.WALKING,
            //     }).then(res => {
            //         res.rows[0].elements.forEach(element => {
            //             distances.push(element.distance.value)
            //         })
            //     })
            //     return distances
            // }

            let coordinates: CoordinatesObj[] = []
            let imgUrls: string[] = []

            service.nearbySearch(
                { location: mobiento, radius: 500, type: 'restaurant' },
                (results, status) => {
                    if (status !== 'OK' || !results) {
                        errors.push(status)
                        reject(errors)
                        return
                    }

                    results.forEach(result => {
                        const coord = result.geometry?.location?.toJSON()
                        if (coord) {
                            coordinates.push(coord)
                        }
                        const imgArr = result.photos
                        if (imgArr) {
                            const imgUrl = imgArr[0].getUrl()
                            imgUrls.push(imgUrl)
                        } else {
                            imgUrls.push('no image')
                        }
                    })

                    // removed for now cause of costs 
                    // getDistances(coordinates).then(res => {
                    //     for (let i = 0; i < results.length; i++) {
                    //         restaurants.push({
                    //             ...results[i],
                    //             distance: res[i],
                    //             imgUrl: imgUrls[i]
                    //         })
                    //     }
                    //     resolve(restaurants)
                    // })

                    for (let i = 0; i < results.length; i++) {
                        restaurants.push({
                            ...results[i],
                            imgUrl: imgUrls[i]
                        })
                    }

                    resolve(restaurants)
                })
        })
        return promise
    }

    fetchRestaurantDetails = (placeId: string): Promise<google.maps.places.PlaceResult> => {        
        const promise = new Promise<google.maps.places.PlaceResult>((resolve, reject) => {
            const map = new google.maps.Map(
                document.getElementById('map') as HTMLElement,
                {
                    center: mobiento,
                } as google.maps.MapOptions
            )
            
            const service = new google.maps.places.PlacesService(map)
            
            const request = {
                placeId: placeId,
                fields: ['name', 'formatted_address', 'formatted_phone_number', 'website', 'rating'],
            }
            service.getDetails(request, (place, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK && place) {
                    resolve(place)
                } else if (status !== google.maps.places.PlacesServiceStatus.OK) {
                    reject(status)
                }
            })
        })
        return promise
    }
}
