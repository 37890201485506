import LunchPlan from './LunchPlan'
import { LunchPlanOutData } from '../../types/lunchPlanApi.type'
import { User } from '../../models/api/APILunchPlan'
import { LunchPlanType } from '../../types/enum/enums'
import { useContext } from 'react'
import * as S from './LunchPlanGroup.styles'
import { UserGlobalDataContext } from '../../context/User.context'
import RestaurantInfoSection from '../Restaurants/RestaurantInfoSection'
import { GeoPoint } from 'firebase/firestore'
import { formatLeavingAt } from '../../helpers/DateHelper'
import { lunchPlanDuration } from '../../types/constant/Constants'

const visibleParticipants: number = 4

export const setExtraParticipants = (participantsLength: number) => {
  return participantsLength > visibleParticipants
    ? `+${participantsLength - visibleParticipants}`
    : ''
}

export const setParticipants = (
  participants: User[],
  visibleParticipants: number
) => {
  const participantsArr: string[] = []
  participants.forEach((participant) => {
    participantsArr.push(participant.imgUrl)
  })
  return participantsArr.slice(0, visibleParticipants)
}

export const lunchPlanGroupVisible = (
  lunchPlanGroupPlans: number[],
  filteredPlans: number[]
) => {
  return lunchPlanGroupPlans.some((plan) => filteredPlans.includes(plan))
}

const LunchPlanGroup = (props: LunchPlanOutData) => {
  const userGlobalData = useContext(UserGlobalDataContext)

  const setUserInCurrentLunchPlan = (participants: User[]) => {
    return participants.some(
      (participant) => participant.email === userGlobalData.email
    )
  }

  return (
    <S.Main
      mainVisible={lunchPlanGroupVisible(
        props.lunchPlanTypes,
        props.filteredLunchPlans
      )}
    >
      <S.RestaurantInfo>
        <RestaurantInfoSection
          id={props.id}
          name={props.destination}
          distance={props.distance}
          location={new GeoPoint(0, 0)}
          cuisines={props.cuisines}
          href={props.href}
        />
      </S.RestaurantInfo>
      {props.lunchPlans.map((lunchPlan, i) => (
        <LunchPlan
          key={i}
          participantsArr={lunchPlan.participants}
          docId={lunchPlan.docId}
          capacity={lunchPlan.capacity}
          type={lunchPlan.type}
          leavingAt={formatLeavingAt(lunchPlan.seconds)}
          participantImgUrls={setParticipants(
            lunchPlan.participants,
            visibleParticipants
          )}
          extraParticipants={setExtraParticipants(
            lunchPlan.participants.length
          )}
          styleMoreThanVisibleParticipants={
            lunchPlan.participants.length > visibleParticipants
          }
          styleLunchPlanIsDineIn={lunchPlan.type === LunchPlanType.DINE_IN}
          styleLunchPlanIsPickup={lunchPlan.type === LunchPlanType.PICK_UP}
          stylePlanNotJoinable={
            userGlobalData.userInLunchPlan ||
            lunchPlan.type === LunchPlanType.INVALID
          }
          filteredLunchPlans={props.filteredLunchPlans}
          userInCurrentLunchPlan={setUserInCurrentLunchPlan(
            lunchPlan.participants
          )}
          destination={props.destination}
          stylePlanOutdated = {lunchPlan.seconds * 1000 + lunchPlanDuration < new Date().getTime()}
        />
      ))}
    </S.Main>
  )
}

export default LunchPlanGroup
