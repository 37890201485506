import { FirestoreError } from 'firebase/firestore'
import { APILunchPlan } from '../models/api/APILunchPlan'
import { LunchPlanDataService } from '../services/LunchPlan.API.service'
import { LunchPlanOutData } from '../types/lunchPlanApi.type'

const lunchTypes = (lpgroup: APILunchPlan[]) => {
  const arr: number[] = []
  lpgroup.forEach((group: APILunchPlan) => {
    arr.push(group.type)
  })
  return arr
}

export class LunchPlanApiRepository {
  lunchPlanDataService: LunchPlanDataService

  constructor() {
    this.lunchPlanDataService = new LunchPlanDataService()
  }

  getOnSnapshotData = (
    callBack: (result: LunchPlanOutData[]) => void, callBackError: (error: FirestoreError) => void
  ): void => {
    this.lunchPlanDataService.initLunchPlanFetch(
      (lunchPlans: APILunchPlan[]) => {
        const lunchPlanMap = new Map<string, APILunchPlan[]>()
        lunchPlans.forEach((lunchPlan) => {
          const restaurantName = lunchPlan.destination.name
          if (lunchPlanMap.has(restaurantName)) {
            for (let [key, value] of lunchPlanMap) {
              if (key === restaurantName) {
                value.push(lunchPlan)
              }
            }
          } else {
            lunchPlanMap.set(restaurantName, [lunchPlan])
          }
        })

        const result: LunchPlanOutData[] = []

        lunchPlanMap.forEach((LPGroup) => {
          const lunchPlans: LunchPlanOutData = {
            id: LPGroup[0].destination.id,
            destination: LPGroup[0].destination.name,
            distance: LPGroup[0].destination.distance,
            cuisines: LPGroup[0].destination.cuisines,
            href: '',
            lunchPlanTypes: lunchTypes(LPGroup),
            filteredLunchPlans: [],
            lunchPlans: LPGroup,
          }
          result.push(lunchPlans)
        })
        callBack(result)
      },
      (error: FirestoreError) => {
        callBackError(error)
      }
    )
  }
}
