import styled from 'styled-components'
import { device } from '../../styles/global'

export interface MainProps {
  mainVisible: boolean;
}

export const Main = styled.div <MainProps>`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  margin: 0 24px 24px;
  overflow: auto;
  display: ${(props) => (props.mainVisible ? 'block' : 'none')};
  padding: 1rem 1rem 0;
  @media ${device.desktop} {
    margin: 0 0 24px;
    padding: 32px 48px 0;
  }
`

export const RestaurantInfo = styled.div`
  padding: 0 0 1rem;
  @media ${device.desktop} {
    padding: 0 0 1.5rem;
  }
`
