import styled from 'styled-components'
import { ButtonPageAction, device } from '../../styles/global'
import { FontWeight } from '../../styles/variables'
import { DetailPageColumnPosition } from '../../types/enum/enums'

interface CustomLinkProps {
  urlExists: boolean;
}

interface GraphicInfoRowHeadingProps {
  align: DetailPageColumnPosition
}

export const Main = styled.div`
  padding-left: 24px;
  @media ${device.desktop} {
    width: 1160px;
    margin: 0 auto;
    padding-left: 24px;
  }
`

export const HeaderContainer = styled.div`
  padding-left: 24px;
  @media ${device.desktop} {
    margin: 0 auto;
    padding-left: 40px;
  }
`

export const GraphicInfoRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
  font-weight: ${FontWeight.semiBold}; 
  @media ${device.desktop} {
    justify-content: left;
    margin: 0 auto;
    padding-left: 40px;
    margin-top: 24px;
  }
`

export const RatingColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  @media (max-width: 480px) {
    padding-right: 32px;
  }
`

export const PriceColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 32px;
  align-items: center;
  @media (max-width: 480px) {
    padding: 0 32px;
    border-left: solid 1px;
  }
`

export const DistanceColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding-left: 32px;
  @media (max-width: 480px) {
    align-items: center;
    border-left: solid 1px;
  }
`

export const GraphicInfoRowHeading = styled.div<GraphicInfoRowHeadingProps>`
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  color: #6c6c6c;
  align-self: ${(props) => props.align};
`

export const RestaurantName = styled.div`
  font-weight: ${FontWeight.medium};
  font-size: 32px;
  letter-spacing: -0.65px;
  margin-top: 24px;
  @media ${device.desktop} {
    font-size: 42px;
    line-height: 55px;
    margin-top: 32px;
  }
`

export const RestaurantDetailHeader = styled.div<CustomLinkProps>`
  margin-top: 4px;
  font-weight: ${FontWeight.regular};
  font-size: 14px;
  line-height: 18px;
  color: #6c6c6c;
  cursor: ${(props) => (props.urlExists ? 'pointer' : 'none')};
  color: ${(props) => (props.urlExists ? '#212121' : '#bfbfbf')};
  @media ${device.desktop} {
    margin-top: 12px;
    font-size: 16px;
    line-height: 24px;
  }
`

export const LineBreak = styled.div`
  margin-top: 24px;
  border-top: 0.3px solid #c6c6c8;
  @media ${device.desktop} {
    width: 1140px;
    margin: 24px auto 0;
  }
`

export const LineBreakMobileOnly = styled(LineBreak)`
  @media ${device.desktop} {
    display: none;
  } 
`

export const RestaurantDetailSubHeader = styled.div`
  margin: 24px 0 12px;
  font-weight: ${FontWeight.regular};
  font-size: 14px;
  line-height: 18px;
  color: #6c6c6c;
  @media ${device.desktop} {
    font-size: 16px;
    line-height: 24px;
    margin-top: 24px;
  }
`

export const RestaurantAddress = styled(RestaurantDetailHeader)`
  margin: 0 0 6px;
  line-height: 16px;
  text-decoration: ${(props) => (props.urlExists ? 'underline' : 'none')};
  @media ${device.desktop} {
    line-height: 24px;
    margin: 0 0 8px;
  }
`

export const Web = styled(RestaurantDetailHeader)`
  text-decoration: ${(props) => (props.urlExists ? 'underline' : 'none')};
`

export const ButtonContainer = styled.div`
  @media ${device.desktop} {
    display: flex;
    justify-content: center;
  }
`

export const SubmitButton = styled(ButtonPageAction)`
  margin-top: 24px;
  button {
    background: #212121;
    color: #ffffff;
    cursor: pointer;
  }
  & button:hover {
    text-decoration: underline;
  }
  & button:active {
    background: #ffffff;
    color: #212121;
  }
  @media ${device.desktop} {
    width: 362px;
    margin-top: 40px;
  }
`

export const RestaurantImage = styled.img`
  width: 100%;
  @media ${device.desktop} {
    width: 270px;
  }
  `

export const Header = styled.div`
  @media ${device.desktop} {
    display: flex;
    margin: 32px auto 0;
    width: 1140px;
  }
  `

export const HeaderLineBreak = styled.hr`
  margin-top: 24px;
  border-top: 0.3px solid #c6c6c8;
  @media ${device.desktop} {
    margin-left: 40px;
}
`