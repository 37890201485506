import styled from 'styled-components'
import { ButtonItemAction, device } from '../../styles/global'
import { FontWeight } from '../../styles/variables'

interface ButtonRestaurantSortProps {
  activeButton: boolean;
}

export const Main = styled.div`
  @media ${device.desktop} {
    width: 584px;
    margin: 0 auto;
  }
`
export const Header = styled.div`
  padding: 0 1.5rem;
  font-size: 32px;
  font-weight: ${FontWeight.medium};
  letter-spacing: -0.02em;
  margin: 24px 0 27px;
  line-height: 45px;
  @media ${device.desktop} {
    padding: 0 1rem;
    margin: 32px 0 40px;
    font-size: 42px;
    line-height: 55px;
  }
`

export const RestaurantSearchContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const RestaurantSearchInput = styled.input`
  border: none;
  outline: none;
  width: 100%;
  font-size: 16px;
  font-weight: ${FontWeight.regular};
  text-indent: 24px;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #bfbfbf;
  }
  :-ms-input-placeholder {
    color: #bfbfbf;
  }
  @media ${device.desktop} {
    text-indent: 2rem;
  }
`

export const RestaurantSearchInputBase = styled.div`
  border-bottom: 1px solid #404040;
  margin: 10px 26px 0;
  @media ${device.desktop} {
    margin: 13px 1rem 0;
    border-bottom: 1px solid #bfbfbf;
  }
`

export const ResetSearchButton = styled.button`
  border: none;
  background-color: #ffffff;
  font-size: 20px;
  line-height: 20px;
  margin-right: 35px;
  color: #212121;
  cursor: pointer;
`

export const RestaurantButtonContainer = styled.div`
  display: flex;
  margin: 24px 0 8px 24px;
  @media ${device.desktop} {
    margin: 32px 1rem 40px;
  }
`

export const RestaurantList = styled.div`
  overflow-y: scroll;
  height: 500px;
`

export const SortRestaurantButton = styled(ButtonItemAction) <ButtonRestaurantSortProps>`
  background-color: ${(props) => (props.activeButton ? '#eaeaea' : '#ffffff')};
  color: #212121;
  margin-right: 8px;
  padding: 8px 16px;
  border: 1px solid #e5e5e5;
  line-height: 18px;
  font-weight: ${FontWeight.regular};
  cursor: pointer;
`