import { useContext } from 'react'
import { logOut } from '../../firebase'
import { UserGlobalDataContext } from '../../context/User.context'
import { Routes } from '../../types/enum/enums'
import GoBack from '../UI/GoBack'
import Loading from '../UI/Loading'
import { useGetMsUserImg } from '../../hooks/api/useApi'
import * as S from './UserProfile.styles'
import { loginIsMicrosoft } from '../../helpers/AuthHelper'

const UserProfile = () => {
  const userGlobalData = useContext(UserGlobalDataContext)

  const { data: msImgUrl } = useGetMsUserImg(userGlobalData.email)
  const googleImgUrl = userGlobalData.imgUrl

  const imgUrl = loginIsMicrosoft ? msImgUrl : googleImgUrl

  return (
    <>
      <GoBack route={Routes.HOME} destinationText={'to home'} />
      {userGlobalData.email ? (
        <S.Main>
          <S.ProfileHeader>Profile</S.ProfileHeader>
          <S.Avatar alt='' src={imgUrl} />
          <S.ProfileSubHeader>Display Name</S.ProfileSubHeader>
          <S.UserName>{userGlobalData.name}</S.UserName>
          <S.ProfileSubHeader>Email address</S.ProfileSubHeader>
          <S.UserEmail>{userGlobalData.email}</S.UserEmail>
          <S.SignOutButton inactive={false} onClick={logOut}>
            <button>Sign Out</button>
          </S.SignOutButton>
        </S.Main>
      ) : (
        <Loading />
      )}
    </>
  )
}

export default UserProfile
