import styled from 'styled-components'
import { device } from '../../styles/global'

export const RestaurantListItemStyle = styled.div`
  margin: 1rem 1.5rem 1.5rem;
  padding: 16px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 14px;
  @media ${device.desktop} {
    margin: 1rem 1rem 2rem;
    border-radius: 16px;
    box-shadow: 0 2px 25px rgba(0, 0, 0, 0.15);
  }
`
