import { useContext, useEffect, useState } from 'react'
import { dateSuffix } from '../../helpers/DateHelper'
import LunchPlanGroup from './LunchPlanGroup'
import { filterOptions } from '../../helpers/LunchPlanTypeHelper'
import { useHistory } from 'react-router'
import { UserGlobalDataContext } from '../../context/User.context'
import { LunchPlanOutData } from '../../types/lunchPlanApi.type'
import { LunchPlanType, Routes } from '../../types/enum/enums'
import * as S from './LunchPlans.styles'
import Loading from '../UI/Loading'
import { LunchPlanApiRepository } from '../../respositories/LunchPlan.API.repository'
import LoadError from '../UI/LoadError'
import { loginIsMicrosoft } from '../../helpers/AuthHelper'

const lunchPlanApiRepository: LunchPlanApiRepository =
  new LunchPlanApiRepository()

const LunchPlans = () => {
  const history = useHistory()

  const today = new Date()
  const suffix = dateSuffix(today.getDate().toString())
  const date =
    today.toLocaleDateString('EN', { month: 'long', day: 'numeric' }) + suffix

  const [filteredLunchPlans, setFilteredLunchPlans] =
    useState<number[]>(filterOptions)

  const userGlobalData = useContext(UserGlobalDataContext)

  const [lunchPlanList, setLunchPlanList] = useState<LunchPlanOutData[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    lunchPlanApiRepository.getOnSnapshotData((data) => {
      setLunchPlanList(data)
      setIsLoading(false)
    }, () => setIsError(true))
  }, [])

  const handleClickLunchPlanFilter = (lunchplanType: LunchPlanType) => {
    switch (lunchplanType) {
      case LunchPlanType.DINE_IN:
        setFilteredLunchPlans([LunchPlanType.DINE_IN])
        break
      case LunchPlanType.TAKEAWAY:
        setFilteredLunchPlans([LunchPlanType.PICK_UP, LunchPlanType.TAKEAWAY])
        break
      default:
        setFilteredLunchPlans(filterOptions)
        break
    }
  }

  const setButtonStatus = (lunchPlanType: number) => {
    return (
      filteredLunchPlans.length < filterOptions.length &&
      filteredLunchPlans.includes(lunchPlanType)
    )
  }

  const goToLocalRestaurants = () => {
    if (!userGlobalData.userInLunchPlan) {
      history.push(Routes.LOCALRESTAURANTS)
    }
  }

  const userName = loginIsMicrosoft ? userGlobalData.name.split(' ')[1] : userGlobalData.name.split(' ')[0]

  const lunchPlanWithUser = lunchPlanList.filter((lunchPlan) =>
    lunchPlan.lunchPlans.some((plan) =>
      plan.participants.some(
        (participant) => participant.email === userGlobalData.email
      )
    )
  )

  const lunchPlansWithoutUser = lunchPlanList.filter(
    (lunchPlan) => lunchPlan !== lunchPlanWithUser[0]
  )

  return (
    <S.Main>
      <S.Header>
        <S.DateField>{date}</S.DateField>
        <S.TitleFilterContainer>
          <S.TextField>Lunch plans</S.TextField>
          <S.LunchPlanTypeContainer>
            <S.LunchPlanTypeButton
              activeButton={filteredLunchPlans.length === filterOptions.length}
              onClick={() => handleClickLunchPlanFilter(LunchPlanType.ALL)}
            >
              All
            </S.LunchPlanTypeButton>
            <S.LunchPlanTypeButton
              activeButton={setButtonStatus(LunchPlanType.TAKEAWAY)}
              onClick={() => handleClickLunchPlanFilter(LunchPlanType.TAKEAWAY)}
            >
              Takeaway
            </S.LunchPlanTypeButton>
            <S.LunchPlanTypeButton
              activeButton={setButtonStatus(LunchPlanType.DINE_IN)}
              onClick={() => handleClickLunchPlanFilter(LunchPlanType.DINE_IN)}
            >
              Dine-in
            </S.LunchPlanTypeButton>
          </S.LunchPlanTypeContainer>
        </S.TitleFilterContainer>
        <S.HeaderFooter />
      </S.Header>
      {isError ? <LoadError list={'Lunch Plans'} /> :
        <>
          {lunchPlanWithUser.length > 0 && (
            <>
              <S.SeparationText>
                You have an upcoming lunch plan, {userName}
              </S.SeparationText>
              {isLoading ? (
                <Loading />
              ) : (
                lunchPlanWithUser.map((lunchPlan, i) => (
                  <LunchPlanGroup
                    id={lunchPlan.id}
                    key={i}
                    destination={lunchPlan.destination}
                    distance={lunchPlan.distance}
                    lunchPlanTypes={lunchPlan.lunchPlanTypes}
                    filteredLunchPlans={filteredLunchPlans}
                    lunchPlans={lunchPlan.lunchPlans}
                    cuisines={lunchPlan.cuisines}
                    href={lunchPlan.href}
                  />
                ))
              )}
              {lunchPlansWithoutUser.length > 0 && (
                <S.SeparationText>Other Plans</S.SeparationText>
              )}
            </>
          )}
          {isLoading ? (
            <Loading />
          ) : lunchPlanList.length > 0 ? (
            <>
              {lunchPlansWithoutUser.length > 0 &&
                lunchPlanWithUser.length < 1 && (
                  <S.SeparationText>
                    Here's what's happening, {userName}
                  </S.SeparationText>
                )}
              {lunchPlansWithoutUser.map((lunchPlan, i) => (
                <LunchPlanGroup
                  id={lunchPlan.id}
                  key={i}
                  destination={lunchPlan.destination}
                  distance={lunchPlan.distance}
                  lunchPlanTypes={lunchPlan.lunchPlanTypes}
                  filteredLunchPlans={filteredLunchPlans}
                  lunchPlans={lunchPlan.lunchPlans}
                  cuisines={lunchPlan.cuisines}
                  href={lunchPlan.href}
                />
              ))}
            </>
          ) : (
            <S.EmptyContentContainer>
              <S.EmptyContentHeader>No plans found.</S.EmptyContentHeader>
              <S.EmptyContent>
                Looks like no one has posted any plans yet. <br />
                Search for a lunch spot and add a new plan.
              </S.EmptyContent>
            </S.EmptyContentContainer>
          )}
          {isLoading ? null : (
            <S.SubmitButton inactive={userGlobalData.userInLunchPlan}>
              <button onClick={goToLocalRestaurants}>Create a lunch plan</button>
            </S.SubmitButton>
          )}
        </>
      }
    </S.Main>
  )
}

export default LunchPlans
