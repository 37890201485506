import axios from 'axios'
import { Routes } from '../types/enum/enums'

const HEREMAPS_URL =
  'https://places.ls.hereapi.com/places/v1/browse?cat=restaurant&app_id=psaOWQxslndjDD1EOV3G&app_code=WH9opxxQu_wQ4MfVnueIFw&in=59.3426,18.0615;r=500&size=100'

export class HereMapsApiService {
  getAllRestaurants = (): Promise<void> => {
    const promise = new Promise<void>((resolve, reject) => {
      axios
        .get(HEREMAPS_URL, { baseURL: Routes.HOME })
        .then((res: any) => {
          const restaurants = res.data.results.items
          resolve(restaurants)
        })
        .catch((err) => reject(err))
    })
    return promise
  }

  getRestaurantDetails = (api: string): Promise<void> => {
    const promise = new Promise<void>((resolve, reject) => {
      axios
        .get(api, { baseURL: Routes.HOME })
        .then((res: any) => {
          const details = res.data
          resolve(details)
        })
        .catch((err) => reject(err))
    })
    return promise
  }
}
