import { addDoc, collection, GeoPoint } from 'firebase/firestore'
import { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import firebase from '../../firebase'
import {
  selectableHours,
  selectableMinutesFunc,
} from '../../helpers/DropdownSelectOptions'
import { HandleTimeChange } from '../../helpers/LunchPlanCreateHelper'
import { UserGlobalDataContext } from '../../context/User.context'
import {
  useFetchLunchPlans,
  useGetRestaurantsFromGoogle,
} from '../../hooks/api/useApi'
import { User } from '../../models/api/APILunchPlan'
import { NEW_PLAN_CREATED_ALERT } from '../../types/constant/copy/Alerts'
import { firestoreCollection, LunchPlanType, Platform, Routes } from '../../types/enum/enums'
import { RestaurantListData } from '../../types/restaurantList.type'
import { onChangeSelectElementEvent } from '../../types/types'
import GoBack from '../UI/GoBack'
import * as S from './CreateLunchPlan.styles'
import { UserEmptyState } from '../../types/enum/emptyStates'
import { MS_IMG_LINK_PREFIX, MS_IMG_LINK_SUFFIX } from '../../types/constant/copy/WebLinks'

const CreateLunchPlan = () => {
  const history = useHistory()
  const location = useLocation()
  const itemId = location.pathname.split('/').pop()

  const [lunchPlanType, setLunchPlanType] = useState(LunchPlanType.TAKEAWAY)
  const [activeButtonValue, setActiveButtonValue] = useState(
    LunchPlanType.TAKEAWAY
  )
  const [lunchPlanIsDuplicated, setLunchPlanIsDuplicated] = useState(false)
  const [showError, setShowError] = useState(true)

  const { data: restaurants } = useGetRestaurantsFromGoogle()
  const [restaurantInfo, setRestaurantInfo] = useState({
    name: '',
    distance: 0,
    id: '',
    location: new GeoPoint(0, 0),
    cuisines: [''],
  })

  const userGlobalData = useContext(UserGlobalDataContext)

  const imgUrl = MS_IMG_LINK_PREFIX + userGlobalData.email + MS_IMG_LINK_SUFFIX

  const participants: User[] = [
    {
      name: userGlobalData.name,
      imgUrl: userGlobalData.imgUrl === UserEmptyState.AVATAR ? imgUrl : userGlobalData.imgUrl,
      telephone: userGlobalData.telephone,
      email: userGlobalData.email,
      platform: Platform.WEB
    },
  ]

  const [departureTime, setDepartureTime] = useState({
    hours: selectableHours[0],
    minutes: '00',
  })

  useEffect(() => {
    restaurants.forEach((element: RestaurantListData) => {
      if (element.id === itemId) {
        setRestaurantInfo({
          name: element.name,
          distance: element.distance ? element.distance : 0,
          id: element.id,
          location: element.location,
          cuisines: element.cuisines,
        })
      }
    })
  }, [restaurants, itemId])

  const handleTimeChange = (e: onChangeSelectElementEvent) =>
    HandleTimeChange(e, setDepartureTime, departureTime)

  const setDepartureDate = (h: string, m: string) => {
    return new Date(`${new Date().toLocaleDateString('fr-CA')}T${h}:${m}:00`)
  }

  const [disableButton, setDisableButton] = useState(false)

  const addNewDoc = async () => {
    setDisableButton(true)
    setShowError(false)
    await addDoc(collection(firebase, firestoreCollection), {
      created: {
        timestamp: new Date(),
        creator: { name: userGlobalData.name, email: userGlobalData.email },
        platform: Platform.WEB
      },
      destination: {
        name: restaurantInfo.name,
        distance: restaurantInfo.distance,
        id: restaurantInfo.id,
        location: new GeoPoint(0, 0),
        cuisines: [],
      },
      participants: participants,
      type: lunchPlanType,
      departure: setDepartureDate(departureTime.hours, departureTime.minutes),
    })
      .then(() => {
        alert(NEW_PLAN_CREATED_ALERT)
        history.push(Routes.LUNCHPLANS)
      })
      .catch((e) => alert(e.message))
  }

  const { data: lunchplans } = useFetchLunchPlans()

  useEffect(() => {
    if (lunchplans.some((plans) => plans.destination === restaurantInfo.name &&
      plans.lunchPlans.some((plan) => plan.type === lunchPlanType) &&
      plans.lunchPlans.some(
        (plan) =>
          plan.seconds ===
          new Date(
            setDepartureDate(departureTime.hours, departureTime.minutes)
          ).getTime() /
          1000
      )
    )) {
      setLunchPlanIsDuplicated(true)
    } else {
      setLunchPlanIsDuplicated(false)
    }
    setActiveButtonValue(lunchPlanType)
  }, [lunchplans, restaurantInfo, lunchPlanType, departureTime])

  const selectableMinutes = selectableMinutesFunc(departureTime.hours)

  return (
    <>
      <GoBack route={`/localrestaurants/${itemId}`} destinationText={''} />
      <S.CreateLunchPlanContainer>
        <S.Header>Choose lunch type</S.Header>
        <S.LunchPlanTypeButtonContainer>
          <S.LunchPlanTypeButton
            activeButton={activeButtonValue === LunchPlanType.TAKEAWAY}
            onClick={() => setLunchPlanType(LunchPlanType.TAKEAWAY)}
          >
            Takeaway
          </S.LunchPlanTypeButton>
          <S.LunchPlanTypeButton
            activeButton={activeButtonValue === LunchPlanType.DINE_IN}
            onClick={() => setLunchPlanType(LunchPlanType.DINE_IN)}
          >
            Dine-In
          </S.LunchPlanTypeButton>
        </S.LunchPlanTypeButtonContainer>
        <S.LineBreak />
        <S.DepartureTimeHeader>What time are you leaving?</S.DepartureTimeHeader>
        <S.DepartureTimeHeading>Leave office today, at...</S.DepartureTimeHeading>
        <S.DepartureTimeSelectContainer>
          <S.TakeawayDropdownSelect
            onChange={handleTimeChange}
            name='hours'
            defaultValue={departureTime.hours}
          >
            {selectableHours.map((hour, i) => (
              <option key={i} value={hour}>
                {hour}
              </option>
            ))}
          </S.TakeawayDropdownSelect>
          <S.TakeawayDropdownSelect
            onChange={handleTimeChange}
            name='minutes'
            defaultValue={departureTime.minutes}
          >
            {selectableMinutes.map((minute, i) => (
              <option key={i} value={minute}>
                {minute}
              </option>
            ))}
          </S.TakeawayDropdownSelect>
        </S.DepartureTimeSelectContainer>
        {showError && lunchPlanIsDuplicated && <S.DuplicateLunchPlanWarning>
          A duplicate lunch plan for this restaurant and time already exists. Join them or change your leaving time to create an additional plan.
        </S.DuplicateLunchPlanWarning>}
      </S.CreateLunchPlanContainer>
      <S.ButtonContainer>
        <S.SubmitLunchPlanButton inactive={lunchPlanIsDuplicated && showError}>
          <button disabled={disableButton} onClick={addNewDoc}>Submit</button>
        </S.SubmitLunchPlanButton>
      </S.ButtonContainer>
    </>
  )
}

export default CreateLunchPlan
