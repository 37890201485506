import { signIn } from '../../firebase'
import * as S from './Home.styles'
import { Main } from '../LunchPlans/LunchPlans.styles'

const Home = () => {

  return (
    <>
      <S.HeaderContainer>
        <S.HeaderLogoContainer>
          <S.HeaderLogo alt='' src='img/logo.png' />
        </S.HeaderLogoContainer>
      </S.HeaderContainer>
      <Main>
        <S.HeaderOne>
          <S.TextSectionP1>Our internal platform</S.TextSectionP1>
          <S.TextSectionP2 data-test='header-text'>Discover Restaurants Near you</S.TextSectionP2>
        </S.HeaderOne>
        <S.HeaderTwo>
          <S.TextSectionP3 data-test='subheader-text'>
            See where your colleagues are going for lunch and join, or create your own lunch plan.
          </S.TextSectionP3>
        </S.HeaderTwo>
        <S.ButtonContainer>
          <S.LogInButton data-test='login-button' inactive={false} onClick={signIn}>
            <button>Log in</button>
          </S.LogInButton>
        </S.ButtonContainer>
      </Main>
    </>
  )
}

export default Home
