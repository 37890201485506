import firebase from '../firebase'
import {
  collection,
  orderBy,
  query,
  where,
  onSnapshot,
  FirestoreError,
} from 'firebase/firestore'
import {
  APILunchPlan,
  APIDestination,
  User,
} from '../models/api/APILunchPlan'
import { firestoreCollection, LunchPlanType, Platform } from '../types/enum/enums'
import { UserEmptyState } from '../types/enum/emptyStates'
import { MS_IMG_LINK_PREFIX } from '../types/constant/copy/WebLinks'

const date = new Date()
const today = date.toLocaleDateString('fr-CA')

const start = new Date(`${today}T06:00:00.000`)
const end = new Date(`${today}T23:59:59.000`)

const lunchPlansDb = query(
  collection(firebase, firestoreCollection),
  orderBy('departure'),
  where('departure', '>=', start),
  where('departure', '<=', end)
)

export class LunchPlanDataService {
  initLunchPlanFetch = (
    callBack: (lunchPlans: APILunchPlan[]) => void, callBackError: (error: FirestoreError) => void
  ): void => {
    onSnapshot(lunchPlansDb, (querySnapshot) => {
      const lunchPlans: APILunchPlan[] = []
      querySnapshot.forEach((doc) => {
        const rawData = doc.data()

        const plan: APILunchPlan = {
          docId: doc.id,
          type: rawData.type ?? LunchPlanType.INVALID,
          capacity: rawData.capacity ?? 0,
          participants: rawData.participants,
          seconds: rawData.departure?.seconds ?? 0,
          destination: rawData.destination,
        }

        let destination: APIDestination
        if (rawData.destination) {
          const apIDestination = rawData.destination
          destination = {
            id: apIDestination.id ?? '',
            distance: apIDestination.distance ?? 0,
            name: apIDestination.name ?? 'Restaurant name N/A',
            cuisines: apIDestination.cuisines ?? ['Cuisines N/A'],
          }
          plan.destination = destination
        } else {
          destination = {
            id: '',
            distance: 0,
            name: 'Restaurant name N/A',
            cuisines: ['Cuisines N/A'],
          }
          plan.destination = destination
        }

        const participants: User[] = []
        if (rawData.participants) {
          rawData.participants.forEach((participant: User) => {
            const user: User = {
              name: participant.name ?? 'Name N/A',
              imgUrl: (!participant.imgUrl || participant.imgUrl.includes(MS_IMG_LINK_PREFIX)) ? UserEmptyState.AVATAR : participant.imgUrl,
              telephone: participant.telephone ?? '',
              email: participant.email ?? 'Email N/A',
              platform: participant.platform ?? Platform.INVALID,
            }
            const userData = user
            participants.push(userData)
          })
          plan.participants = participants
        } else {
          const user: User = {
            name: 'Name N/A',
            imgUrl: UserEmptyState.AVATAR,
            telephone: '',
            email: 'Email N/A',
            platform: Platform.INVALID,
          }
          const userData = user
          participants.push(userData)
          plan.participants = participants
        }
        lunchPlans.push(plan)
      })
      callBack(lunchPlans)
    }, (error) => { callBackError(error) })
  }
}
